export enum Region {
    AKKALA = "AKKALA",
    CENTRAL_HYRULE = "CENTRAL_HYRULE",
    DUELING_PEAKS = "DUELING_PEAKS",
    ELDIN = "ELDIN",
    FARON = "FARON",
    GERUDO_HIGHLANDS = "GERUDO_HIGHLANDS",
    GERUDO_WASTELAND = "GERUDO_WASTELAND",
    GREAT_HYRULE_FOREST = "GREAT_HYRULE_FOREST",
    GREAT_PLATEAU = "GREAT_PLATEAU",
    EAST_NECLUDA = "EAST_NECLUDA",
    HEBRA = "HEBRA",
    LAKE_HYLIA = "LAKE_HYLIA",
    LANAYRU = "LANAYRU",
    RIDGELAND = "RIDGELAND",
    TABANTHA = "TABANTHA",
    NONE = "NONE",
    CHAMPIONS_BALLAD = "CHAMPIONS_BALLAD"
}