export enum Effect {
    ATTACK="ATTACK",
    CHILLY="CHILLY",
    DEFENSE="DEFENSE",
    ELECTRIC="ELECTRIC",
    ENDURANCE="ENDURANCE",
    HEART="HEART",
    HEARTY="HEARTY",
    RANDOM="RANDOM",
    SPEED="SPEED",
    SPICY="SPICY",
    STAMINA="STAMINA",
    STEALTH="STEALTH",
    TIME="TIME",
    ELIXER="ELIXER",
    ELIXER_ATTACK="ELIXER_ATTACK",
    ELIXER_CHILLY="ELIXER_CHILLY",
    ELIXER_DEFENSE="ELIXER_DEFENSE",
    ELIXER_ELECTRIC="ELIXER_ELECTRIC",
    ELIXER_FIREPROOF="ELIXER_FIREPROOF",
    ELIXER_SPEED="ELIXER_SPEED",
    ELIXER_SPICY="ELIXER_SPICY",
    ELIXER_STAMINA="ELIXER_STAMINA",
    ELIXER_ENDURANCE="ELIXER_ENDURANCE",
    ELIXER_STEALTH="ELIXER_STEALTH",
    ELIXER_HEARTY="ELIXER_HEARTY",
    ROCK_HARD="ROCK_HARD",
    NONE="NONE",
    DUBIOUS="DUBIOUS",
}