import React, {useContext, useEffect} from "react";
import {MeContext} from "../context/MeContext";
import Loading from "./Loading";
import {UserArmorsMaterial} from "../models/UserArmorsMaterial";
import {UserArmorDisplay} from "../models/UserArmorDisplay";
import {
    Card,
    Grid,
    Typography,
    Theme, createStyles, Button, CardHeader, CardContent
} from "@material-ui/core";
import BotwListItem from "./BotwListItem";
import {fade, makeStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import {ArmorContext} from "../context/ArmorContext";
import {FoodContext} from "../context/FoodContext";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles( {
        root: {
            maxWidth: "325px"
        },
        card: {
            paddingTop: theme.spacing(2),
            paddingBottom:theme.spacing(2),
            background: fade(theme.palette.common.black, .8),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.common.white
        },
        img: {
            maxWidth: "100%",
            height: "auto"
        },
        cardTitle: {
            marginBottom: theme.spacing(1)
        },
        addButton: {
            margin: theme.spacing(2),
            marginTop: theme.spacing(5),
            borderWidth: 3
        },
        transverseContainer: {
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2)
        }
    })
))

const UserArmorMaterialsList = () => {
    const {
        userArmorMaterialsState,
        getUserArmorMaterials,
        meState,
        userArmorInventoryState,
        updateUserArmorLevelState,
        updateUserArmorActiveState
    } = useContext(MeContext);

    const { addArmorState } = useContext(ArmorContext);
    const { setSearchFoodMaterialsQuery } = useContext(FoodContext);

    const classes = useStyles();

    //to-do change <NavLink />s to React.forwardRefs
    // https://material-ui.com/guides/composition/#caveat-with-refs

    if (userArmorMaterialsState.loading && userArmorMaterialsState.data == null) {
        return <Loading message={"userArmor"} />
    }

    if (meState.error) {
        return <>Error: { JSON.stringify(meState.error)}</>
    }

    if (userArmorInventoryState.error) {
        return <>Error: { JSON.stringify(userArmorInventoryState.error)}</>
    }

    if (userArmorMaterialsState.error) {
        return <>Error: { JSON.stringify(userArmorMaterialsState.error)}</>
    }

    const userArmorMaterials: UserArmorsMaterial[] = userArmorMaterialsState.data!!;

    const inspectMaterial = (userArmorMaterial: UserArmorsMaterial) => {

    }

    if (userArmorMaterials.length === 0) {
        return (
            <Card className={classes.card}>
                <CardHeader title="No armor being tracked rn" />
                <CardContent>

                    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography variant="body2" >As you add Armors and set their level, this list will populate with the materials you need to upgrade your armor.</Typography>
                        </Grid>
                        <Grid item><Typography variant="body2">You can de-select armor that you don't want to track.</Typography></Grid>
                        <Grid item>
                            <NavLink to="/armor" onClick={ () => {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                })
                            }}>
                                <img className={classes.img} src="/images/addArmorDemo.png" alt="adding armor demonstration" />
                            </NavLink>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        )
    }
    return(
        <Grid container spacing={1} className={classes.transverseContainer}>
            {userArmorMaterials.length > 0 && userArmorMaterials?.map( (i: UserArmorsMaterial, index: number) => (
                <Grid item xs={6} sm={12} md={4} lg={3} xl={2} key={index} onClick={() => inspectMaterial(i)}>
                    <NavLink to={"/cooking"} onClick={() => setSearchFoodMaterialsQuery({text: i.material.displayName}) } style={{ textDecoration: 'none' }}>
                        <BotwListItem direction="column" alignItems="center">
                            <Grid item xs={12} container justify="center" alignItems="center" >
                                <Typography variant={"h5"}>{i.materialCount}x </Typography>
                                <img src={"/images/materials/" + i.material.imgUrl} alt={i.material.displayName} height={50} width={50} />
                            </Grid>
                            <Typography variant="caption" align="center">{i.material.displayName}</Typography>
                        </BotwListItem>
                    </NavLink>
                </Grid>
            ))}
        </Grid>
    );
};

export { UserArmorMaterialsList as default }
