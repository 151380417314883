import React, {useContext, useState} from 'react';
import { useAuth0 } from '../react-auth0-spa';
import {
  ActionState,
  createActionState,
  createDataActionState,
  createErrorActionState,
  createLoadingActionState
} from "./ActionState";
import {Armor} from "../models/Armor";
import {CONFIG} from "../config";
import {UserArmorDisplay} from "../models/UserArmorDisplay";
import {MeContext} from "./MeContext";

interface ArmorContextProps {
  getAllArmor: () => Promise<ActionState<Armor[]>>;
  armorInventoryState: ActionState<Armor[]>;

  setArmorViewState: (armorViewState: ActionState<Armor[]>) => void;
  armorViewState: ActionState<Armor[]>;

  setUserArmorViewState: (userArmorViewState: ActionState<UserArmorDisplay[]>) => void;

  setArmorInventoryState: (armorInventoryState: ActionState<Armor[]>) => void;
  userArmorInventoryState: ActionState<UserArmorDisplay[]>;

  setSearchArmorQuery: (searchArmorQuery: SearchArmorQuery) => void;
  searchArmorQueryState: SearchArmorQuery;

  addArmorToInventory: (token: string, userId: number, armor: Armor) => Promise<ActionState<UserArmorDisplay>>;
  addArmorState: ActionState<UserArmorDisplay>;

  removeArmorFromInventory: (token: string, userId: number, armor: Armor) => Promise<ActionState<UserArmorDisplay>>;
  removeArmorState: ActionState<UserArmorDisplay>;
}

interface SearchArmorQuery {
  text: string;
}

interface Props {
  children: React.ReactNode;
}

export const  ArmorContext = React.createContext<ArmorContextProps>({} as ArmorContextProps);

export const ArmorContextProvider = (props: Props) => {
  const [armorViewState, setArmorViewState] = useState<ActionState<Armor[]>>(createActionState());
  const [armorInventoryState, setArmorInventoryState] = useState<ActionState<Armor[]>>(createActionState());
  const [searchArmorQueryState, setSearchArmorQueryState] = useState<SearchArmorQuery>({} as SearchArmorQuery);
  const [addArmorState, setAddArmorState] = useState<ActionState<UserArmorDisplay>>(createActionState());
  const [removeArmorState, setRemoveArmorState] = useState<ActionState<UserArmorDisplay>>(createActionState());
  const { meState, userArmorInventoryState, setUserArmorInventoryState, setUserArmorViewState, tokenState } = useContext(MeContext);

  const getAllArmor = async () => {
    try {
      setArmorViewState(createLoadingActionState(armorViewState));

      const response = await fetch(CONFIG.apiRoot + '/armor', {
        headers: {
          Authorization: `Bearer ${tokenState.data}`,
        },
      });

      const armor: Armor[] = await response.json();
      setArmorViewState(createDataActionState(armor));
      setArmorInventoryState(createDataActionState(armor));
      return Promise.resolve(armorViewState);

    } catch (error) {
      console.error(error);
      setArmorViewState(createErrorActionState(error, armorViewState));
      setArmorInventoryState(createErrorActionState(error, armorInventoryState));
      return Promise.resolve(armorViewState);
    }
  };

  const setSearchArmorQuery = (newSearchQuery: SearchArmorQuery) => {
    if (!newSearchQuery.text && searchArmorQueryState.text) {
      setArmorViewState(createDataActionState(armorInventoryState.data!!));
      setUserArmorViewState(createDataActionState(userArmorInventoryState.data!!));
      setSearchArmorQueryState(newSearchQuery);
      return;
    }
    setSearchArmorQueryState(newSearchQuery);

    if (newSearchQuery.text) {
      setUserArmorViewState(
          createDataActionState(
              userArmorInventoryState.data!!.filter((userArmorDisplay: UserArmorDisplay) => {
                return userArmorDisplay.armor.displayName!!.toLowerCase().indexOf(
                    newSearchQuery.text.toLowerCase()) >= 0;}
              ))
      )
      setArmorViewState(
          createDataActionState(
              armorInventoryState.data!!.filter((armor: Armor) => {
                return armor.displayName!!.toLowerCase().indexOf(newSearchQuery.text.toLowerCase()) >= 0;
              }))
      )

    }
  }

  const addArmorToInventory = async (token: string, userId: number, armor: Armor) => {
    // set local version for visual loading before fetching and updating
    try {
      const userArmor: UserArmorDisplay = {
        user: {
          id: meState.data!!.id,
          firstName: meState.data!!.firstName,
          lastName: meState.data!!.lastName,
          profilePictureUrl: meState.data!!.profilePictureUrl
        },
        armor: armor,
        armorLevel: 0,
        active: true
      }
      setUserArmorInventoryState(createDataActionState((userArmorInventoryState.data || []).concat(userArmor)));

      //api
      setAddArmorState(createLoadingActionState(addArmorState));
      const response = await fetch(
          CONFIG.apiRoot + '/users/' + userId + '/armor/' + armor.id,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${tokenState.data}`,
            },
          }
      );

      const newUserArmor: UserArmorDisplay = await response.json();
      setAddArmorState(createDataActionState(newUserArmor));
      return Promise.resolve(addArmorState);

    } catch (error) {
      console.error(error);
      setAddArmorState(createErrorActionState(error, addArmorState));
      return Promise.resolve(addArmorState);
    }
  };

  const removeArmorFromInventory = async (token: string, userId: number, armor: Armor) => {
    //local
    setUserArmorInventoryState(createDataActionState((userArmorInventoryState.data || []).filter(userArmor => userArmor.armor !== armor)));

    //api
    try {
      setRemoveArmorState(createLoadingActionState(removeArmorState));
      const response = await fetch(
          CONFIG.apiRoot + '/users/' + userId + '/armor/' + armor.id,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${tokenState.data}`,
            },
          }
      );

      const deleteUserArmor: UserArmorDisplay = await response.json();
      setRemoveArmorState(createDataActionState(deleteUserArmor));
      return Promise.resolve(removeArmorState);

    } catch (error) {
      console.error(error);
      setRemoveArmorState(createErrorActionState(error, removeArmorState));
      return Promise.resolve(removeArmorState);
    }
  };

  return (
    <ArmorContext.Provider
      value={{
        getAllArmor, setArmorViewState, armorViewState,
        armorInventoryState, setArmorInventoryState,
        setSearchArmorQuery, searchArmorQueryState,
        userArmorInventoryState, setUserArmorViewState,
        addArmorToInventory, addArmorState,
        removeArmorFromInventory, removeArmorState,
      }}
    >
      {props.children}
    </ArmorContext.Provider>
  );
};

