import React, {useContext, useState} from 'react';
import {
  ActionState,
  createActionState,
  createDataActionState,
  createErrorActionState,
  createLoadingActionState
} from "./ActionState";
import {Food} from "../models/Food";
import {CONFIG} from "../config";
import {MeContext} from "./MeContext";
import {UserFood} from "../models/UserFood";
import {Effect} from "../models/Effect";
import {FoodPrep} from "../models/FoodPrep";
import {
  calculateCookedHeartsFromMaterialsAndEffect,
  calculateCookingEffectFromMaterials,
  calculateEffectTimeFromMaterials,
  calculatePointsFromMaterialsAndEffect,
  calculateTotalMealValue,
  createsIncompleteElixer,
  elixerEffects,
  foodEffects,
  foodHasElixer
} from "../lib/CookingCalcs";
import {FoodMaterial} from "../models/FoodMaterial";
import footer from "../components/Footer";

interface FoodContextProps {
  getFood: () => Promise<ActionState<Food[]>>;
  foodInventoryState: ActionState<Food[]>;
  setFoodViewState: (foodViewState: ActionState<Food[]>) => void;
  foodViewState: ActionState<Food[]>;

  setFoodInventoryState: (foodInventoryState: ActionState<Food[]>) => void;

  addFoodToInventory: (userId: number, food: Food) => Promise<ActionState<UserFood>>;
  addFoodState: ActionState<UserFood>;

  removeFoodFromInventory: (userId: number, food: Food) => Promise<ActionState<UserFood>>;
  removeFoodState: ActionState<UserFood>;

  getAllFoodMaterials: () => Promise<ActionState<FoodMaterial[]>>;
  setFoodMaterialsState: (foodMaterialsState: ActionState<FoodMaterial[]>) => void;
  foodMaterialsState: ActionState<FoodMaterial[]>;
  foodMaterialsViewState: ActionState<FoodMaterial[]>;
  setFoodMaterialsViewState: (foodMaterialsViewState: ActionState<FoodMaterial[]>) => void;

  getFoodMaterialsByEffect: (effect: Effect, includeElixers?: boolean) => void;
  addMaterialToFood: (cookedMaterial: FoodMaterial) => void;
  foodPrepState: ActionState<FoodPrep>;
  setFoodPrepState: (foodPrepState: ActionState<FoodPrep>) => void;

  currentEffectFilterState: ActionState<Effect>;
  setCurrentEffect: (effect: Effect) => void;
  initFood: () => void;
  removeMaterialFromFood: (index: number) => void;

  setSearchFoodMaterialsQuery: (searchFoodMaterialsQuery: SearchFoodMaterialsQuery) => void;
  searchFoodMaterialsQueryState: SearchFoodMaterialsQuery;

  filterArmorFood: boolean;
  setFilterArmorFood: (filterArmorFood: boolean) => void;
}

interface SearchFoodMaterialsQuery {
  text: string;
}

interface Props {
  children: React.ReactNode;
}

export const FoodContext = React.createContext<FoodContextProps>({} as FoodContextProps);

export const FoodContextProvider = (props: Props) => {

  const [foodViewState, setFoodViewState] = useState<ActionState<Food[]>>(createActionState());
  const [foodInventoryState, setFoodInventoryState] = useState<ActionState<Food[]>>(createActionState());
  const [addFoodState, setAddFoodState] = useState<ActionState<UserFood>>(createActionState());
  const [removeFoodState, setRemoveFoodState] = useState<ActionState<UserFood>>(createActionState());
  const [foodPrepState, setFoodPrepState] = useState<ActionState<FoodPrep>>(createActionState());
  const [filterArmorFood, setFilterArmorFood] = useState<boolean>(false);

  const [foodMaterialsState, setFoodMaterialsState] = useState<ActionState<FoodMaterial[]>>(createActionState());
  const [foodMaterialsViewState, setFoodMaterialsViewState] = useState<ActionState<FoodMaterial[]>>(createActionState());

  const [currentEffectFilterState, setCurrentEffectState] = useState<ActionState<Effect>>(createActionState());

  const [searchFoodMaterialsQueryState, setSearchFoodMaterialsQueryState] = useState<SearchFoodMaterialsQuery>({} as SearchFoodMaterialsQuery);

  const { tokenState, userFoodInventoryState, setUserFoodInventoryState, userArmorMaterialsState } = useContext(MeContext);

  const setCurrentEffect = (effect: Effect) => {
    setCurrentEffectState(createDataActionState(effect));
  }

  const getFood = async () => {
    try {
      setFoodViewState(createLoadingActionState(foodViewState));

      const token = tokenState.data!!;
      const response = await fetch(CONFIG.apiRoot + '/food', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const food: Food[] = await response.json();
      setFoodViewState(createDataActionState(food));
      setFoodInventoryState(createDataActionState(food));
      return Promise.resolve(foodViewState);

    } catch (error) {
      console.error(error);
      setFoodViewState(createErrorActionState(error, foodViewState));
      setFoodInventoryState(createErrorActionState(error, foodInventoryState));
      return Promise.resolve(foodViewState);
    }
  };

  const addFoodToInventory = async (userId: number, food: Food) => {
    // set local version for visual loading before fetching and updating
    try {
      const userFood: UserFood = {
        id: 0,
        foodId: food.id,
        displayName: food.displayName || "",
        active: true
      }
      setUserFoodInventoryState(createDataActionState((userFoodInventoryState.data || []).concat(userFood)));

      //api
      setAddFoodState(createLoadingActionState(addFoodState));
      const token = tokenState.data!!;
      const response = await fetch(
          CONFIG.apiRoot + '/users/' + userId + '/food/' + food.id,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );

      const newUserFood: UserFood = await response.json();
      setAddFoodState(createDataActionState(newUserFood));
      return Promise.resolve(addFoodState);

    } catch (error) {
      console.error(error);
      setAddFoodState(createErrorActionState(error, addFoodState));
      return Promise.resolve(addFoodState);
    }
  };

  const removeFoodFromInventory = async (userId: number, food: Food) => {
    //local
    setUserFoodInventoryState(createDataActionState((userFoodInventoryState.data || []).filter(userFood => userFood.foodId !== food.id)));

    //api
    try {
      setRemoveFoodState(createLoadingActionState(removeFoodState));
      const token = tokenState.data!!;
      const response = await fetch(
          CONFIG.apiRoot + '/users/' + userId + '/food/' + food.id,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );

      const deleteUserFood: UserFood = await response.json();
      setRemoveFoodState(createDataActionState(deleteUserFood));
      return Promise.resolve(removeFoodState);

    } catch (error) {
      console.error(error);
      setRemoveFoodState(createErrorActionState(error, removeFoodState));
      return Promise.resolve(removeFoodState);
    }
  };

  const getAllFoodMaterials = async () => {
    try {
      setFoodMaterialsViewState(createLoadingActionState(foodMaterialsViewState));
      const token = tokenState.data!!;
      const response = await fetch(CONFIG.apiRoot + '/materials/food', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const foodMaterials: FoodMaterial[] = await response.json();
      setFoodMaterialsViewState(createDataActionState(foodMaterials.filter((foodMaterial) => {
        if (userArmorMaterialsState && userArmorMaterialsState.data && filterArmorFood) {
          return !userArmorMaterialsState.data.some( (userArmorsMaterial) => {
            return userArmorsMaterial.material.id  === foodMaterial.material.id
          });
        }
        return foodMaterial
      })));
      setFoodMaterialsState(createDataActionState(foodMaterials.filter((foodMaterial) => {
        if (userArmorMaterialsState && userArmorMaterialsState.data && filterArmorFood) {
          return !userArmorMaterialsState.data.some((userArmorsMaterial) => {
            return userArmorsMaterial.material.id === foodMaterial.material.id
          });
        }
        return foodMaterial
      })));
      return Promise.resolve(foodMaterialsViewState);

    } catch (error) {
      console.error(error);
      setFoodMaterialsViewState(createErrorActionState(error, foodMaterialsViewState));
      setFoodMaterialsState(createErrorActionState(error, foodMaterialsState));
      return Promise.resolve(foodMaterialsViewState);
    }
  }

  const triageMatchedMaterialsByEffect = (effect: Effect, fi1: FoodMaterial, fi2: FoodMaterial) => {
      let fi1Score: number = 0;
      let fi2Score: number = 0;

      const fi1Effect = fi1.effectName.replace("ELIXER_", "");
      const fi2Effect = fi2.effectName.replace("ELIXER_", "");
      const allEffectsMatter = effect.replace("ELIXER_", "");

      fi1Score += fi1Effect === allEffectsMatter ? 10 : 0;
      fi2Score += fi2Effect === allEffectsMatter ? 10 : 0;

      fi1Score += fi1.effectName === Effect.HEART ? 5 : 0;
      fi2Score += fi2.effectName === Effect.HEART ? 5 : 0;

      return fi1Score === fi2Score ? 0 : fi1Score < fi2Score ? 1 : -1;
  }

  const getFoodMaterialsByEffect = (effect: Effect, includeElixers?: boolean) => {
    const effectsInCurrentFoodPrep = foodPrepState.data?.foodMaterials.map( (fm) => fm.effectName)
    const matchedMaterials: FoodMaterial[] = foodMaterialsState.data?.filter( (fm) => {
      switch(effect) {
        case Effect.HEARTY: {
          return fm.effectName === Effect.HEART || fm.effectName === Effect.HEARTY || (includeElixers && fm.effectName === "ELIXER_" + effect)
        }
        case Effect.SPICY:
        case Effect.SPEED:
        case Effect.STEALTH:
        case Effect.CHILLY:
        case Effect.ATTACK:
        case Effect.DEFENSE:
        case Effect.ELECTRIC: {
          if (fm.effectName === effect || (includeElixers && fm.effectName === "ELIXER_" + effect)) {
            return true;
          }
          return fm.effectName === Effect.TIME || fm.effectName === Effect.HEART;
        }

        case Effect.ENDURANCE:
        case Effect.STAMINA: {
          return fm.effectName === effect || (includeElixers && fm.effectName === "ELIXER_" + effect) ||
              fm.effectName === Effect.HEART;
        }
        case Effect.DUBIOUS:
        case Effect.ELIXER_ATTACK:
        case Effect.ELIXER_CHILLY:
        case Effect.ELIXER_ELECTRIC:
        case Effect.ELIXER_DEFENSE:
        case Effect.ELIXER_FIREPROOF:
        case Effect.ELIXER_SPEED:
        case Effect.ELIXER_SPICY:
        case Effect.ELIXER_STEALTH:
          if (foodPrepState.data?.foodMaterials.length === 4 && !foodHasElixer(effectsInCurrentFoodPrep!)) return fm.effectName === Effect.ELIXER;
          return fm.effectName === effect || fm.effectName === Effect.ELIXER || (includeElixers && (fm.effectName === Effect.TIME || fm.effectName === effect));

        case Effect.ELIXER_HEARTY:
        case Effect.ELIXER_ENDURANCE:
        case Effect.ELIXER_STAMINA:
          if (foodPrepState.data?.foodMaterials.length === 4 && !foodHasElixer(effectsInCurrentFoodPrep!)) return fm.effectName === Effect.ELIXER;
          return fm.effectName === effect || fm.effectName === Effect.ELIXER || (fm.effectName === Effect.TIME && fm.cookedHearts > 0);
        case Effect.ELIXER:
          if (foodPrepState.data?.foodMaterials.length === 4 && createsIncompleteElixer(effectsInCurrentFoodPrep!)) return (fm.effectName in elixerEffects && fm.effectName !== Effect.ELIXER);
          return elixerEffects.includes(fm.effectName);
        case Effect.HEART:
          return foodEffects.includes(fm.effectName) || fm.effectName === Effect.HEART || fm.effectName === Effect.TIME;
        default:
          return true;
      }
    }) || [];

    setFoodMaterialsViewState(createDataActionState(matchedMaterials.sort( (a, b) => triageMatchedMaterialsByEffect(effect, a,b))));
  }

  const initFood = (): void => {
    setFoodPrepState(createDataActionState({
      foodMaterials: [],
      foodEffect: Effect.NONE,
      effectPoints: 0,
      effectPointRange: 0,
      cookedHearts: 0,
      effectTime: 0,
      rupees: 0
    }));
  }

  const addMaterialToFood = (cookedMaterial: FoodMaterial): FoodPrep => {
    const foodPrep = foodPrepState.data || {
      foodEffect: Effect.NONE,
      foodMaterials: [],
      effectPoints: 0,
      effectPointRange: 0,
      effectTime: 0,
      cookedHearts: 0,
      rupees: 0
    }

    if (foodPrep.foodMaterials.length === 5) {
      // setFoodPrepState(createErrorActionState("Five materials, max.", foodPrepState));
      return foodPrep;
    }

    foodPrep.foodMaterials = foodPrep.foodMaterials.concat(cookedMaterial);
    foodPrep.foodEffect = calculateCookingEffectFromMaterials(foodPrep.foodMaterials);
    foodPrep.effectPoints = calculatePointsFromMaterialsAndEffect(foodPrep.foodMaterials, foodPrep.foodEffect);
    foodPrep.effectTime = calculateEffectTimeFromMaterials(foodPrep.foodMaterials, foodPrep.foodEffect);
    foodPrep.cookedHearts = calculateCookedHeartsFromMaterialsAndEffect(foodPrep.foodMaterials, foodPrep.foodEffect);
    foodPrep.rupees = calculateTotalMealValue(foodPrep.foodMaterials);
    setFoodPrepState(createDataActionState(foodPrep));

    return foodPrep;
  }

  const removeMaterialFromFood = (index: number): void => {
    if (foodPrepState.data) {
      let newPrep: FoodMaterial[] = [];
      foodPrepState.data.foodMaterials.map( (fm: FoodMaterial, i: number) => {
        console.log(fm.material.displayName, i, index);
        if (i !== index) {
          console.log(`adding ${fm.material.displayName}`)
          newPrep.push(fm);
        }
      })

      const tempFoodPrep = {
        ...foodPrepState.data!!,
        foodMaterials: newPrep
      }
      tempFoodPrep.foodEffect = calculateCookingEffectFromMaterials(tempFoodPrep.foodMaterials);
      tempFoodPrep.effectPoints = calculatePointsFromMaterialsAndEffect(tempFoodPrep.foodMaterials, tempFoodPrep.foodEffect);
      tempFoodPrep.effectTime = calculateEffectTimeFromMaterials(tempFoodPrep.foodMaterials, tempFoodPrep.foodEffect);
      tempFoodPrep.cookedHearts = calculateCookedHeartsFromMaterialsAndEffect(tempFoodPrep.foodMaterials, tempFoodPrep.foodEffect);
      tempFoodPrep.rupees = calculateTotalMealValue(tempFoodPrep.foodMaterials);

      setFoodPrepState(createDataActionState(tempFoodPrep));
    }
  }

  const setSearchFoodMaterialsQuery = (newSearchQuery: SearchFoodMaterialsQuery) => {
    if (!newSearchQuery.text && searchFoodMaterialsQueryState.text) {
      setFoodMaterialsViewState(createDataActionState(foodMaterialsState.data!!));
      setSearchFoodMaterialsQueryState(newSearchQuery);
      return;
    }
    setSearchFoodMaterialsQueryState(newSearchQuery);

    setFoodMaterialsViewState(
        createDataActionState(
            foodMaterialsState.data!!.filter((foodMaterial: FoodMaterial) => {
              return foodMaterial.material.displayName.toLowerCase().indexOf(newSearchQuery.text.toLowerCase()) >= 0;
            }))
    )
  }


  return (
      <FoodContext.Provider
          value={{
            filterArmorFood, setFilterArmorFood,
            getFood, setFoodViewState, foodViewState,
            foodInventoryState, setFoodInventoryState,
            setCurrentEffect, currentEffectFilterState,
            addFoodToInventory, addFoodState,
            removeFoodFromInventory, removeFoodState,
            getAllFoodMaterials, setFoodMaterialsState, foodMaterialsState, foodMaterialsViewState, setFoodMaterialsViewState,
            getFoodMaterialsByEffect, addMaterialToFood,
            setFoodPrepState, foodPrepState, initFood, removeMaterialFromFood,
            setSearchFoodMaterialsQuery, searchFoodMaterialsQueryState
          }}
      >
        {props.children}
      </FoodContext.Provider>
  );
};

