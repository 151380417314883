import React from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import {createStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";


const useStyles = makeStyles( (theme: Theme) =>
    createStyles({
        levelContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
        },
        icon: {
            cursor: "pointer"
        },
        dimIcon: {
            cursor: ""
        }

    }),
);

interface Props {
    type: string,
    maxLevel: number,
    level: number,
    updateArmorLevel?: (newArmorLevel: number) => void
}

const LevelIndicator = (props: Props) => {
    const classes = useStyles();
    const { maxLevel, level, updateArmorLevel } = props;

    const indicators: string[] = [];

    for (let i = 1; i <= maxLevel; i++) {
        if (i <= level) {
            indicators.push("filled")
        } else {
            indicators.push("bordered")
        }
    }

    return(
        <div className="levelContainer">
            {indicators.map( (indicator: string, index: number) => {
                if (updateArmorLevel !== undefined) {

                    if (indicator === "filled") {
                        return <StarIcon className={classes.icon} key={index}
                                         onClick={(event) => {
                                             if (index + 1 === level) {
                                                 updateArmorLevel(0);
                                             } else {
                                                 updateArmorLevel(index + 1);
                                             }
                                         }}
                        />
                    } else {
                        return <StarBorderIcon className={classes.icon} key={index}
                                               onClick={(event) => updateArmorLevel(index + 1)}
                        />
                    }
                } else {
                    return <StarIcon className={classes.dimIcon} color="disabled" key={index} />
                }

            })}
        </div>
    );
};

export { LevelIndicator as default }