import React, {useState} from 'react';
import {History} from "history";
import {browserHistory} from "../lib/browserHistory";
import Api from "../lib/Api";

interface Props {
    children: JSX.Element;
}

interface CommonContextState {
    history: History;
    api: Api;
    redirect: (redirectUri: string) => void;
}

export const CommonContext = React.createContext({} as CommonContextState);

export const CommonContextProvider = (props: Props) => {
    const [history] = useState<History>(browserHistory);
    const [api] = useState<Api>(new Api(browserHistory));

    const redirect = (uri: string) => {
        history.push(uri);
    };

    return (
        <CommonContext.Provider
            value={{
                history,
                api,
                redirect
            }}
        >
            {props.children}
        </CommonContext.Provider>
    );
};
