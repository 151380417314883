import {makeStyles} from "@material-ui/core/styles";
import {
    Box,
    Container,
    createStyles,
    fade, FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Slide,
    SwipeableDrawer, Switch,
    Tab,
    Tabs,
    Theme, useMediaQuery,
} from "@material-ui/core";
import bg from "../assets/bg-cooking.jpg";
import React, {useContext, useEffect, useMemo, useState} from "react";
import FoodMaterialsList, {useScrollTabsToTop} from "../components/FoodMaterialsList";
import {FoodContext} from "../context/FoodContext";
import {Effect} from "../models/Effect";
import {BackspaceOutlined, Check, DeleteSweep, Settings} from "@material-ui/icons";
import Loading from "../components/Loading";
import EffectIndicator from "../components/EffectIndicator";
import {
    createsDubiousFood,
    createsIncompleteElixer,
    createsIncompleteTime,
} from "../lib/CookingCalcs";
import {FoodMaterial} from "../models/FoodMaterial";
import botw_theme from "../styles/theme";
import BotwFoodMaterialsAutocomplete from "../components/BotwFoodMaterialsAutocomplete";
import Rupees from "../components/Rupees";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
        root: {
            background: `url(${bg})`,
            backgroundSize: "cover",
            paddingTop: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(1)
            }
        },
        paperFoodContainer: {
            display: "none",
            [theme.breakpoints.up('sm')]: {
                background: fade(theme.palette.common.black, .5),
                maxHeight: "70vh",
                overflow: "scroll",
                padding: theme.spacing(2),
                marginLeft: theme.spacing(-2),
                marginRight: theme.spacing(-2)
            }

        },
        foodDrawer: {
            background: "rgba(0,0,0,.25)",
            [theme.breakpoints.up('sm')]: {
                display: "none"
            }
        },
        paperMaterialsContainer: {
            maxHeight: "70vh",
            overflow: "scroll",
            padding: theme.spacing(2),
            background: fade(theme.palette.grey.A700, .5)
        },
        paper: {
            background: fade(theme.palette.common.black, .8),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            height: "25px"
        },
        paperWarning: {
            background: fade(theme.palette.error.main, .5),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        foodGrid: {
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                maxWidth: theme.breakpoints.values.sm,
            },
            position: "relative"
        },
        materialsGrid: {
            display: "none",
            marginTop: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                display: "block"
            }
        },
        materialsHeader: {
            paddingBottom: theme.spacing(2),
            fontFamily: "Hylia"
        },
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 0.4em rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '4px solid rgba(0,0,0,.5)'
            },
        },
        slideContainer: {
            overflowY: "scroll",
            display: "flex",
            maxHeight: "100px",
            maxWidth: "100%"
        },
        hyliaLight: {
            fontFamily: "Hylia",
            color: theme.palette.error.contrastText,
            padding: theme.spacing(2)
        },
        hyliaBlue: {
            fontFamily: "Hylia",
            color: theme.palette.primary.main,
            padding: theme.spacing(2)
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        tabPanelContainer: {
            display: 'flex',
            background: "rgba(0,0,0, .5)",
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
            maxHeight: "70vh",
            overflowY: "scroll",
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            display: "inline-flex",
            maxWidth: "100%",
            height: "100%",
            overflowY: "scroll",
            background: "rgba(0,0,0,.5)",
        },
        tab: {
            maxWidth: "100%",
            justifyContent: "flex-start",
            minWidth: "unset"
        },
        tabPanel: {
            maxHeight: "100%",
            maxWidth: "100%",
            overflowY: "scroll",
            padding: theme.spacing(1),
            display: "inline-flex",
            flexDirection: "column"
        },
        ".MuiTabScrollButton-vertical": {
            color: theme.palette.primary.main
        }
    })
));

const Cooking = () => {
    const classes = useStyles();
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const { getFoodMaterialsByEffect, initFood, foodPrepState, removeMaterialFromFood, filterArmorFood, setFilterArmorFood } = useContext(FoodContext);
    const {scrollTabsToTop} = useScrollTabsToTop();
    const [panelValue, setPanelValue] = useState<number>(0);
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [settingsActive, setSettingsActive] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(!!foodPrepState.data?.foodMaterials?.length || false);
    const smUp = useMediaQuery(botw_theme.breakpoints.up("sm"));

    const foodPrepMaterialsCount = useMemo(() => {
        return foodPrepState?.data?.foodMaterials?.length || 0
    }, [foodPrepState])

    const effectFilterTiles: string[] = [
        "HEARTY",
        "SPICY",
        "CHILLY",
        "STEALTH",
        "SPEED",
        "STAMINA",
        "ENDURANCE",
        "ATTACK",
        "DEFENSE",
        "ELECTRIC",
        "ELIXER_FIREPROOF"
    ];
    let localFoodPrepState = foodPrepState.data!!;

    useEffect(() => {
        if (foodPrepState.data !== null) {
            localFoodPrepState = foodPrepState.data!!;
        }
    }, [foodPrepState.data]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setPanelValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const toggleDrawer = (open: boolean) => (event: { type: string; key: string; }) => {
        alert(event);
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const effectCanceling = (materials: FoodMaterial[]): Effect => {
        const effects = materials.map( (material: FoodMaterial) => material.effectName);
        if (createsDubiousFood(effects)) return Effect.DUBIOUS;
        if (createsIncompleteTime(effects)) return Effect.TIME;
        if (createsIncompleteElixer(effects)) return Effect.ELIXER;
        return Effect.NONE;
    }

    useEffect(() => {
        if (foodPrepState.data) {
            console.log(foodPrepState)
            setDrawerOpen(foodPrepState.data?.foodMaterials.length > 0)
        }

    }, [foodPrepState])

    if (foodPrepState.data === null) {
        return <Loading message={"foodPrep"} />
    }


    return (
        <Container className={classes.root} maxWidth={false}>
            {!smUp && (
                    <Paper
                        className={classes.paper}
                        elevation={4}
                        style={{
                            backgroundColor: fade(botw_theme.palette.common.black, .6),
                            height: "unset",
                            marginTop: botw_theme.spacing(-1)
                    }}
                        onClick={() => setDrawerOpen(true)}
                    >
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Grid item container alignItems="center">
                                <Grid item xs={1}>
                                    <Rupees value={localFoodPrepState.rupees} />
                                </Grid>
                                <Grid item xs={11}>
                                    <EffectIndicator canceling={effectCanceling(localFoodPrepState.foodMaterials)} flatten
                                                     hearts={localFoodPrepState.cookedHearts} time={localFoodPrepState.effectTime} points={localFoodPrepState.effectPoints}
                                                     effect={localFoodPrepState.foodEffect} pointRange={localFoodPrepState.effectPointRange}/>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                )}
            <Grid container spacing={0} justify="center">
                <Grid item container sm={8} md={6} justify="center">
                    {smUp && <Grid item xs={12} className={classes.foodGrid} component={Paper} style={{
                        paddingTop: botw_theme.spacing(2),
                        paddingBottom: "1px",
                        background: fade(botw_theme.palette.common.white, 0.5),
                        marginBottom: botw_theme.spacing(2),
                    }}>
                        <>
                            <Paper className={classes.paper} elevation={4} style={{ position: "relative" }}>
                                <IconButton
                                    onClick={() => {
                                        initFood();
                                        setPanelValue(0);
                                    }}
                                    size="medium"
                                    style={{
                                        position: "absolute",
                                        top: "-12px",
                                        right: "0px",
                                        color: botw_theme.palette.error.main,
                                        display: foodPrepMaterialsCount > 0 ? 'unset' : 'none'
                                    }}><DeleteSweep />
                                </IconButton>
                                <Grid container item xs={9} alignItems="center" justify="space-around"
                                      style={{position: "absolute", marginTop: "-20px"}}>
                                    {
                                        [0, 1, 2, 3, 4].map(i => {
                                            return (
                                                <Grid item key={i} style={{
                                                    position: "relative",
                                                    cursor: "pointer",
                                                    marginRight: "px"
                                                }} onClick={() => removeMaterialFromFood(i)}>
                                                    {(foodPrepState!.data!.foodMaterials[i] !== undefined) && (
                                                        <>
                                                            <img
                                                                src={`/images/materials/${foodPrepState!.data!.foodMaterials[i].material.imgUrl}`}
                                                                alt={foodPrepState!.data!.foodMaterials[i].material.displayName}
                                                                height={50} width={50}/>
                                                            <div style={{
                                                                position: "absolute",
                                                                color: botw_theme.palette.error.main,
                                                                top: "-5px",
                                                                right: "1px",
                                                                borderRadius: "30px",
                                                                width: "17px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                fontSize: "10px",
                                                                border: `1px solid ${botw_theme.palette.error.dark}`,
                                                            }}>X
                                                            </div>
                                                        </>
                                                    ) || <Box style={{color: "white"}}>*</Box>}

                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Paper>
                            <Paper className={classes.paper} elevation={4}
                                   style={{backgroundColor: botw_theme.palette.secondary.dark, height: "unset"}}>
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item container alignItems="center">
                                        <Grid item xs={1}>
                                            <Rupees value={localFoodPrepState.rupees}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <EffectIndicator canceling={effectCanceling(localFoodPrepState.foodMaterials)}
                                                             flatten
                                                             hearts={localFoodPrepState.cookedHearts}
                                                             time={localFoodPrepState.effectTime}
                                                             points={localFoodPrepState.effectPoints}
                                                             effect={localFoodPrepState.foodEffect}
                                                             pointRange={localFoodPrepState.effectPointRange}/>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Paper>
                        </>
                    </Grid>
                    }
                    <Grid container>
                        <div className={classes.tabPanelContainer}>
                            <Grid item xs={2} md={1}>
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={panelValue}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    className={classes.tabs}
                                >
                                    <Tab style={{ color: botw_theme.palette.common.white }} key={0} label={<>&#x1F50E;</>} className={classes.tab}
                                         onClick={(e) => {
                                             e.preventDefault();
                                             setSearchActive(!searchActive)
                                             scrollTabsToTop();
                                         }}
                                    />
                                    {effectFilterTiles.map( (effectName: string, index ) => (
                                        <Tab
                                            key={index + 1}
                                            label={
                                                <img
                                                    src={`/images/cooking/${effectName.replace("ELIXER_", "")}.png`}
                                                    alt={effectName}
                                                />
                                            }
                                            className={classes.tab}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getFoodMaterialsByEffect(effectName as Effect, true);
                                                setSearchActive(false)
                                                scrollTabsToTop();

                                            }}
                                        />
                                    ))}
                                </Tabs>
                            </Grid>
                            <Grid item xs={10} md={11}>
                                <div
                                    role="tabpanel"
                                    className={classes.tabPanel}
                                >
                                    {searchActive && (
                                        <Box display='flex' justifyContent=''>
                                            <FormControlLabel
                                                color={botw_theme.palette.secondary.main}
                                                control={<Switch checked={filterArmorFood} onChange={() => setFilterArmorFood(!filterArmorFood)} name="filterArmorFood" />}
                                                label={<span style={{ color: botw_theme.palette.common.white }}>Filter Armor Ingredients</span>}
                                            />
                                            <BotwFoodMaterialsAutocomplete />
                                        </Box>
                                    )}
                                    <FoodMaterialsList />
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {!smUp &&
                <SwipeableDrawer
                    variant="persistent"
                    ModalProps={{ onBackdropClick: () => {if (foodPrepMaterialsCount < 5) setDrawerOpen(false) }}}
                    anchor="bottom"
                    open={drawerOpen}
                    onClose={() => toggleDrawer(false)}
                    onOpen={() => toggleDrawer(true)}
                >
                    <Grid item  xs={12} className={classes.foodGrid} component={Paper} style={{ position: "relative", paddingTop: botw_theme.spacing(2), paddingBottom: "1px", width: "100%", background: fade(botw_theme.palette.common.black, 0.5), border: `2px solid ${botw_theme.palette.primary.light}`}}>
                        <>
                            <IconButton
                                onClick={() => {
                                    initFood();
                                    setPanelValue(0);
                                }}
                                style={{
                                position: "absolute",
                                top: "-15px",
                                right: "0px",
                                borderRadius: "30px",
                            }}><DeleteSweep />
                            </IconButton>
                            <Paper className={classes.paper} elevation={4}>
                                <Grid container item xs={9} alignItems="center" justify="space-around"
                                      style={{position: "absolute", marginTop: "-20px", overflow: "scroll"}}>
                                    {
                                        [0, 1, 2, 3, 4].map(i => {
                                            return (
                                                <Grid item key={i} style={{
                                                    position: "relative",
                                                    cursor: "pointer",
                                                }} onClick={() => removeMaterialFromFood(i)}>
                                                    {(foodPrepState!.data!.foodMaterials[i] !== undefined) && (
                                                        <>
                                                            <img
                                                                src={`/images/materials/${foodPrepState!.data!.foodMaterials[i].material.imgUrl}`}
                                                                alt={foodPrepState!.data!.foodMaterials[i].material.displayName}
                                                                height={50} width={50}/>
                                                            <div style={{
                                                                position: "absolute",
                                                                color: botw_theme.palette.error.main,
                                                                top: "-5px",
                                                                right: "1px",
                                                                borderRadius: "30px",
                                                                width: "17px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                fontSize: "10px",
                                                                border: `1px solid ${botw_theme.palette.error.dark}`,
                                                            }}>X
                                                            </div>
                                                        </>
                                                    ) || <Box style={{color: "white"}}>*</Box>}

                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Paper>
                        </>
                    </Grid>
                </SwipeableDrawer>
            }
        </Container>
    )
};

export { Cooking as default } ;
