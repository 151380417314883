import ReactGA from 'react-ga';

export default class GoogleAnalytics {
    static initialize = () => {
        ReactGA.initialize('UA-165573260-1');
        GoogleAnalytics.setUserIdFromLocalStorage();
    };
    static pageView = () => {
        GoogleAnalytics.setUserIdFromLocalStorage();
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    static setUserId = (userId: number) => {
        localStorage.setItem('userId', userId.toString());
        ReactGA.set({userId: userId});
    };
    static userEvent = (action: string) =>
        GoogleAnalytics.event(GoogleAnalyticsCategories.USER, action)
    static event = (category: string, action: string) => {
        ReactGA.event({
            category: category,
            action: action
        });
    };
    private static setUserIdFromLocalStorage = () => {
        if (localStorage.getItem('userId')) {
            const userId: string = localStorage.getItem('userId')!!;
            GoogleAnalytics.setUserId(parseInt(userId));
        }
    };
}
export class GoogleAnalyticsCategories {
    static USER = 'USER'
}
export class GoogleAnalyticsActions {
    static REQUEST_TOUR = 'REQUEST_TOUR'
    static INVESTOR_CALCULATOR_USED = 'INVESTOR_CALCULATOR_USED'
    static RENTER_CALCULATOR_USED = 'RENTER_CALCULATOR_USED'
}