import React, {useContext, useState} from "react";
import {MeContext} from "../context/MeContext";
import {Button, Input, Typography} from "@material-ui/core";
import {NavLink} from "react-router-dom";

const Login = () => {
    const { meState } = useContext(MeContext);
    const [loginBlob, setLoginBlob] = useState("");

    return (
        <>
            <Typography variant="h6">Paste your login blob from your <NavLink to="/profile">Profile Page</NavLink> from the device you'd like to log in from</Typography>
            <Input value={loginBlob} onChange={(e) => setLoginBlob(e.target.value)} />
            <Button onClick={() => alert(`dammit jim, i'm a sample button not a login button: ${loginBlob}`)}>
                Login
            </Button>
        </>
)}

export default Login;