import React, {useContext} from "react";
import {createStyles, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import {Effect} from "../models/Effect";
import {Favorite, Filter1, Filter2, Filter3, HourglassEmpty, ReportProblem, Whatshot} from "@material-ui/icons";
import Stamina1 from "../assets/cooking/stamina-1.png";
import Stamina2 from "../assets/cooking/stamina-2.png";
import Stamina3 from "../assets/cooking/stamina-3.png";
import Stamina4 from "../assets/cooking/stamina-4.png";
import Stamina5 from "../assets/cooking/stamina-5.png";
import Endurance1 from "../assets/cooking/endurance-1.png";
import Endurance2 from "../assets/cooking/endurance-2.png";
import Endurance3 from "../assets/cooking/endurance-3.png";
import Endurance4 from "../assets/cooking/endurance-4.png";
import Endurance5 from "../assets/cooking/endurance-5.png";
import Dubious from "../assets/cooking/DUBIOUS.png";
import RockHard from "../assets/cooking/ROCK_HARD.png";
import Loading from "./Loading";
import {FoodContext} from "../context/FoodContext";
import botw_theme from "../styles/theme";
import {PointRange} from "../models/PointRange";
import {calculateThresholdFromEffectandPoints, elixerEffects, foodEffects, maxEffectPoints} from "../lib/CookingCalcs";


const useStyles = makeStyles( (theme: Theme) =>
    createStyles({
        effectContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            flexWrap: "nowrap",
            margin: 0,
        },
        flatContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "nowrap",
            margin: 0,
        },
        icon: {
            cursor: "pointer",
            marginRight: "1px"
        },
        dimIcon: {
            cursor: ""
        },
        nudgeRight: {
            marginRight: "3px"
        },
        cancelContainer: {

        },
        cancel: {
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: 5
        },
        mx3: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3)
        }
    }),
);

interface Props {
    effect: Effect,
    points: number,
    hearts: number,
    time: number,
    canceling?: Effect,
    flatten?: boolean,
    pointRange: number | PointRange
}

const EffectIndicator = (props: Props) => {
    const classes = useStyles();
    const { foodPrepState, getFoodMaterialsByEffect } = useContext(FoodContext);

    const { effect, flatten, points, hearts, time, canceling, pointRange } = props;

    if (foodPrepState.data === null) return <Loading message={"foodPrepState"} />

    const calculateStaminaWheel = (effectPoints: number): JSX.Element => {
        switch(effectPoints) {
            case 0:
                return <></>
            case 1:
                return <img src={Stamina1} alt="1/5 stamina wheel" />;
            case 2:
                return <img src={Stamina2} alt="2/5 stamina wheel" />;
            case 3:
                return <img src={Stamina4} alt="4/5 stamina wheel" />;
            case 4:
                return <img src={Stamina5} alt="1 stamina wheel" />;
            case 5:
                return <><img src={Stamina5} alt="1 and 2/5 stamina wheels" /><img src={Stamina2} alt=""/></> ;
            case 6:
                return <><img src={Stamina5} alt="1 and 3/5 stamina wheels" /><img src={Stamina3} alt=""/></> ;
            case 7:
                return <><img src={Stamina5} alt="1 and 4/5 stamina wheels" /><img src={Stamina4} alt=""/></> ;
            case 8:
                return <><img src={Stamina5} alt="2 and 1/5 stamina wheels" /><img src={Stamina5} alt=""/><img src={Stamina1} alt=""/></>;
            case 9:
                return <><img src={Stamina5} alt="2 and 2/5 stamina wheels" /><img src={Stamina5} alt=""/><img src={Stamina2} alt=""/></> ;
            case 10:
                return <><img src={Stamina5} alt="2 and 4/5 stamina wheels" /><img src={Stamina5} alt=""/><img src={Stamina4} alt=""/></> ;
            default:
                return <><img src={Stamina5} alt="3 stamina wheels" /><img src={Stamina5} alt=""/><img src={Stamina5} alt=""/></> ;
        }
    }
    const calculateEnduranceWheel = (effectPoints: number): JSX.Element => {
        switch(effectPoints) {
            case 0:
                return <></>
            case 1:
                return <img src={Endurance1} alt="1/5 endurance wheel" />
            case 2:
            case 3:
            case 4:
                return <img src={Endurance2} alt="2/5 endurance wheel" />;
            case 5:
            case 6:
                return <img src={Endurance3} alt="3/5 endurance wheels" />
            case 7:
            case 8:
                return <img src={Endurance5} alt="4/5 endurance wheels" />
            case 9:
            case 10:
                return <img src={Endurance5} alt="1 endurance wheel" />
            case 11:
            case 12:
                return <><img src={Endurance5} alt="1 and 1/5 endurance wheels" /><img src={Endurance1} alt=""/></> ;
            case 13:
            case 14:
                return <><img src={Endurance5} alt="1 and 2/5 endurance wheels" /><img src={Endurance2} alt=""/></> ;
            case 15:
            case 16:
                return <><img src={Endurance5} alt="1 and 3/5 endurance wheels" /><img src={Endurance3} alt=""/></> ;
            case 17:
            case 18:
                return <><img src={Endurance5} alt="1 and 4/5 endurance wheels" /><img src={Endurance4} alt=""/></> ;
            default:
                return <><img src={Endurance5} alt="2 endurance wheels" /><img src={Endurance5} alt=""/></> ;
        }
    }
    const effectIcons = (effect: Effect, effectPoints: number): Array<JSX.Element> => {
        if (effect === Effect.DUBIOUS) return [<img src={Dubious} alt={effect} height={35} width={35} />];
        if (effect === Effect.ROCK_HARD) return [<img src={RockHard} alt={effect} height={35} width={35} />];
        if (effectPoints < 0) {
            return [
                <div className={classes.cancelContainer}>
                    <img src={`/images/cooking/${effect.replace("ELIXER_", "")}.png`} alt={effect} style={{ filter: 'grayscale(100%)'}} />
                </div>
            ]
        }

        if (effect.indexOf(Effect.STAMINA) > -1) {
            return [
                calculateStaminaWheel(effectPoints)
            ];
        }

        if (effect.indexOf(Effect.ENDURANCE) > -1) {
            return [
                calculateEnduranceWheel(effectPoints)
            ];
        }

        if (effect === Effect.ELIXER) return [<></>];

        if (effect.indexOf("HEARTY") > -1) {
            return Array<JSX.Element>(effectPoints).fill(<Favorite color="primary" />);
        }
        if (effect === Effect.HEART) {
            return Array<JSX.Element>( effectPoints).fill(<Favorite color="primary" />);
        }

        const length = (effectPoints > maxEffectPoints(effect)) ? maxEffectPoints(effect) : effectPoints;
        return new Array<JSX.Element>(length).fill(effectIcon(effect));
    }

    const calculateElixerStrength = (effectPoints: number): JSX.Element => {
        switch (effectPoints) {
            case 1:
                return (
                    <Typography variant="body1">
                        Tier: <Filter1 fontSize="small" />
                    </Typography>
                );
            case 2:
                return (
                    <Typography variant="body1">
                        Tier: <Filter2 fontSize="small" />
                    </Typography>
                );
            case 3:
                return (
                    <Typography variant="body1">
                        Tier: <Filter3 fontSize="small" />
                    </Typography>
                );
            default:
                return <ReportProblem fontSize="small" color="error" />
        }
    }

    const effectIcon = (effect: Effect): JSX.Element => {
        switch(effect) {
            case Effect.HEART:
            case Effect.ATTACK:
            case Effect.CHILLY:
            case Effect.DEFENSE:
            case Effect.ELECTRIC:
            case Effect.ENDURANCE:
            case Effect.RANDOM:
            case Effect.SPEED:
            case Effect.SPICY:
            case Effect.STAMINA:
            case Effect.STEALTH:
            case Effect.TIME:
                return <img src={`/images/cooking/${effect}.png`} alt={effect} />
            case Effect.ELIXER_ATTACK:
            case Effect.ELIXER_CHILLY:
            case Effect.ELIXER_DEFENSE:
            case Effect.ELIXER_ELECTRIC:
            case Effect.ELIXER_FIREPROOF:
            case Effect.ELIXER_SPEED:
            case Effect.ELIXER_SPICY:
            case Effect.ELIXER_STEALTH:
                return <img src={`/images/cooking/${effect.split('ELIXER_')[1]}.png`} alt={effect} />
            case Effect.ROCK_HARD:
                return <img src={RockHard} alt={effect} />
            case Effect.DUBIOUS:
                return <img src={Dubious} alt={effect} />
            case Effect.NONE:
            default:
                return <></>;

        }
    }

    const effects = effectIcons(effect, calculateThresholdFromEffectandPoints(effect, points));
    const heartArray = new Array(hearts).fill("<3");

    const cancelItem = (): JSX.Element => {
        switch(canceling!) {
            case Effect.DUBIOUS:
                return (
                    <Grid container item onClick={() => getFoodMaterialsByEffect(Effect.TIME)} direction="column" alignItems="center">
                        <img src={Dubious} alt="Dubious - No mixing food and elixers" width={35} height={35} />
                    </Grid>
                )
            case Effect.ELIXER:
                return (
                    <Grid container item onClick={() => getFoodMaterialsByEffect(Effect.ELIXER)} direction="column" alignItems="center">
                        <Whatshot color="disabled" />
                    </Grid>
                    // <Grid container item onClick={() => getFoodMaterialsByEffect(Effect.TIME)} direction="column" alignItems="center">
                    //     <img src={Fla} alt="Dubious - No mixing food and elixers" width={35} height={35} />
                    // </Grid>
                )
            case Effect.TIME:
                return (
                    <Grid container item onClick={() => getFoodMaterialsByEffect(Effect.TIME)} direction="column" alignItems="center">
                        <img src={Dubious} alt="Dubious - Add Food or Elixer with Effect" width={35} height={35} />
                        <Grid item container alignItems={"center"}>
                            <Typography variant="caption" style={{ color: botw_theme.palette.info.main }}>{`Add Effect`}</Typography>
                        </Grid>
                    </Grid>
                )
            case Effect.DUBIOUS:
            case Effect.ELIXER_ATTACK:
            case Effect.ELIXER_CHILLY:
            case Effect.ELIXER_ELECTRIC:
            case Effect.ELIXER_DEFENSE:
            case Effect.ELIXER_FIREPROOF:
            case Effect.ELIXER_SPEED:
            case Effect.ELIXER_SPICY:
            case Effect.ELIXER_STEALTH:
            case Effect.ELIXER:
                return (
                    <Grid container item onClick={() => getFoodMaterialsByEffect(Effect.ELIXER)} direction="column" alignItems="center" id="AddElixer">
                            <img src={Dubious} alt="Dubious - Add Elixer with Effect" width={35} height={35} />
                            <Grid item container alignItems={"center"}>
                                <Whatshot style={{ color: botw_theme.palette.info.main }} />
                                <Typography variant="caption" style={{ color: botw_theme.palette.info.main }}>{` Needed`}</Typography>
                            </Grid>
                    </Grid>
                )
            default:
                return <></>
        }
    }

    return (
        <>
            <Grid item container className={flatten ? classes.flatContainer : classes.effectContainer}>
                {canceling !== undefined &&
                    <Grid item>
                        {cancelItem()}
                    </Grid>
                }
                {effects.length > 0 && (
                    <Grid item className={classes.mx3}>
                        <Grid container justify="flex-start" alignItems="center">
                            {effects.length <= 5 && (
                                effects.map((e, index) => (
                                    <Grid item key={index}>
                                        <div className={index < effects.length && classes.nudgeRight || ""}>
                                            {e}
                                        </div>
                                    </Grid>
                                )))
                            }
                            {effects.length > 5 &&
                                <Grid item container alignItems="center">
                                    {effects[0]}
                                    <Typography variant="body2" color="primary">
                                        x{effects.length}
                                    </Typography>
                                </Grid>

                            }
                        </Grid>
                    </Grid>
                )}
                {time > 0 &&
                    <Grid item className={classes.mx3} >
                        <Grid container alignItems="center" direction="row" wrap="nowrap">
                            <Grid item>
                                <Typography color={points > -1 ? "primary" : "textPrimary"} variant="body2">
                                    <HourglassEmpty fontSize="small"/>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography color={points > -1 ? "primary" : "textPrimary"} variant="body2">
                                    {time}s
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {heartArray.length > 0 && (
                    <Grid item className={classes.mx3}>
                        {heartArray.length <= 5 &&
                            (
                                <Grid container alignItems="center" justify="flex-start">
                                    {heartArray.map((element, index) => (
                                        <Grid item key={index}>
                                            <Favorite color="error"
                                                      fontSize={heartArray.length >= 10 ? "small" : "default"}/>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                        }
                        {(heartArray.length > 5 && heartArray.length < 18) &&
                            (<Grid container alignItems="center">
                                <Favorite color="error" fontSize="default"/>
                                <Typography variant="body2" color="primary">x{heartArray.length}</Typography>
                            </Grid>)
                        }
                        {heartArray.length >= 18 &&
                            (<Grid container alignItems="center">
                                <Favorite color="error" fontSize="default"/>
                                <Typography variant="body2" color="primary">&nbsp;Full Recovery</Typography>
                            </Grid>)
                        }
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export { EffectIndicator as default }