import React, {useContext} from "react";
import {MeContext} from "../context/MeContext";
import {Typography} from "@material-ui/core";

const Profile = () => {
    const { meState } = useContext(MeContext);

    return (
        <>
            <Typography variant="h6">To log this account into another browser, go to https://breadthofthewild.com/login on that device and paste this text blob into the input box</Typography>
            <Typography variant="body1">
                {localStorage.getItem('anonJwt')}
            </Typography>
            <Typography variant="caption" color="error">Warning: If anyone else gets ahold of this, they've got your account info.  I was really lazy, so you'll just have to start over by clearing your cache.</Typography>
        </>
)}

export default Profile;