import React, {useContext, useEffect} from 'react';
import { ArmorContext } from '../context/ArmorContext';
import Loading from './Loading';
import { MeContext } from '../context/MeContext';
import {createStyles, Grid, Theme, Typography} from "@material-ui/core";
import {UserArmorDisplay} from "../models/UserArmorDisplay";
import {Armor} from "../models/Armor";
import UserArmorDisplayTile from './UserArmorDisplayTile';
import {makeStyles} from "@material-ui/core/styles";
import {createDataActionState} from "../context/ActionState";
import BotwArmorAutocomplete from "./BotwArmorAutocomplete";
import {useLocation} from "react-router";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
      root: {
        maxHeight: "100%",
      }
    })
));

interface AllArmorListProps {
  justUserArmor?: boolean
}

const AllArmorList = ({ justUserArmor = false }: AllArmorListProps) => {
  const classes  = useStyles();
  const { armorViewState, setArmorViewState, armorInventoryState, setSearchArmorQuery, searchArmorQueryState } = useContext(ArmorContext);
  const { meState, userArmorViewState, setUserArmorViewState, userArmorInventoryState } = useContext(MeContext);
  const currentRoute = useLocation().pathname

  if (meState.loading && meState.data == null) {
    return <Loading message={"me"}/>;
  }
  if (armorViewState.loading && armorViewState.data == null) {
    return <Loading message={"armor"}/>;
  }
  if (userArmorViewState.loading && userArmorViewState.data == null) {
    return <Loading message={"user armor"}/>;
  }
  if (armorInventoryState.loading && armorInventoryState.data == null) {
    return <Loading message={"filtered armor"}/>;
  }

  if (meState.error) {
    return <>Error, me: {JSON.stringify(meState)}</>;
  }
  if (armorViewState.error) {
    return <>Error armor: {JSON.stringify(armorViewState)}</>;
  }

  if (armorInventoryState.error) {
    return <>Error armor: {JSON.stringify(armorInventoryState)}</>;
  }

  /*
  TRY putting all of these into a useEffect block and see if you can get them to update when the parent functions are called
  */

  return (
    <Grid container className={classes.root}>
      {userArmorViewState.data != null && userArmorViewState.data!!.map( (userArmorDisplay: UserArmorDisplay, index: number) => {
        if (userArmorDisplay.active) {
          return (<Grid item key={index} xs={12}><UserArmorDisplayTile armorDisplay={userArmorDisplay} /></Grid>)
        }
      })}
      {userArmorViewState.data !== null && userArmorViewState.data!!.map( (userArmorDisplay: UserArmorDisplay, index: number) => {
        if (!userArmorDisplay.active) {
          return (<Grid item key={index} xs={12}><UserArmorDisplayTile armorDisplay={userArmorDisplay} /></Grid>)
        }
      })}
      {armorViewState.data !== null && armorViewState.data !== null && currentRoute !== "/upgrades"  && armorViewState.data!!
          .filter((armor: Armor) => {
            return !userArmorViewState.data!!.map((ua: UserArmorDisplay) => ua.armor.id).includes(armor.id);
          })
          .map( (armor: Armor) => (
              <Grid item key={armor.id} xs={12}>
                <UserArmorDisplayTile armor={armor} />
              </Grid>
          ))
      }
    </Grid>
  );
};

export { AllArmorList as default };