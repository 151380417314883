import React, {useEffect, useMemo, useState, useRef, useContext} from "react";
import Paper from "@material-ui/core/Paper";
import {
    Container,
    createStyles,
    fade,
    Grid,
    Theme,
    Typography,
    IconButton,
} from "@material-ui/core";
import bg from "../assets/korok-bg.jpg";
import { makeStyles } from "@material-ui/core/styles";
import koroksData from "../assets/koroks/koroks-copy.json";
import KorokSeedIcon from "../assets/korok_seed_dark.png";
import KorokSeedFoundIcon from "../assets/korok_seed.png";
import useLocalStorage from "use-local-storage";
import {KorokContext} from "../context/KorokContext";
import BotwKoroksAutocomplete from "../components/BotwKoroksAutocomplete";
import {createDataActionState} from "../context/ActionState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundPositionX: "40%",
            height: "100%",
        },
        paperKoroksContainer: {
            maxHeight: "70vh",
            overflowY: "scroll",
            padding: theme.spacing(2),
            background: fade(theme.palette.secondary.dark, .9)
        },
        korokGrid: {
            [theme.breakpoints.up("md")]: {
                maxWidth: theme.breakpoints.values.sm,
            },
        },
        korokCountContainer: {
            background: fade(theme.palette.primary.contrastText, 0.8),
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            display: "flex",
            justifyContent: "space-between",
        },
    })
);

enum TowerRegion {
    AKKALA = "Akkala",
    CENTRAL = "Central",
    DUELING_PEAKS = "Dueling Peaks",
    ELDIN = "Eldin",
    FARON = "Faron",
    GREAT_PLATEAU = "Great Plateau",
    HATENO = "Hateno",
    HEBRA = "Hebra",
    HIGHLANDS = "Highlands",
    LAKE = "Lake",
    LANAYRU = "Lanayru",
    RIDGELAND = "Ridgeland",
    TABANTHA = "Tabantha",
    WASTELAND = "Wasteland",
    WOODLAND = "Woodland",
    UNKNOWN = "Unknown",
}

export interface Seed {
    originalIndex: number;
    loc: string;
    found: boolean;
    region: TowerRegion;
}

interface RegionData {
    region: TowerRegion;
    seeds: Seed[];
}

const Koroks = () => {
    const classes = useStyles();
    const {koroksViewState, setKoroksState} = useContext(KorokContext);
    const [userSeedIndexes, setUserSeedIndexes] = useLocalStorage<number[]>(
        "userKorokSeeds",
        []
    );
    const [seedsByRegion, setSeedsByRegion] = useState<Map<TowerRegion, Seed[]>>(
        new Map()
    );
    const [selectedRegion, setSelectedRegion] = useState<TowerRegion | "all">(
        "all"
    );
    const regionRefMap = useRef<Map<TowerRegion, HTMLDivElement | null>>(
        new Map()
    );

    const userKoroksRemaining = useMemo(() => {
        return 900 - (userSeedIndexes ?? []).length;
    }, [userSeedIndexes]);

    const seedsPerSubregion = (data: Seed[]): Map<string, Seed[]> => {
        const subregionSeedsMap: Map<string, Seed[]> = new Map();

        data.forEach((seed) => {
            const { loc } = seed;
            const seeds = subregionSeedsMap.get(loc) || [];
            seeds.push({
                ...seed,
                found: userSeedIndexes?.includes(seed.originalIndex) || false,
            });
            subregionSeedsMap.set(loc, seeds);
        });
        return subregionSeedsMap;
    };

    const groupByRegion = (data: Seed[]): Map<TowerRegion, Seed[]> => {
        const groupedData: Map<TowerRegion, Seed[]> = new Map();

        data.forEach((seed: Seed) => {
            const { region } = seed;
            const seeds = groupedData.get(region) || [];
            seeds.push(seed);
            groupedData.set(region, seeds);
        });

        return groupedData;
    };

    const handleKorokSeedClick = (originalIndex: number) => {
        if (!userSeedIndexes) return;
        const seedIndexes = new Set(userSeedIndexes);
        const seedInArray = seedIndexes.has(originalIndex);

        setUserSeedIndexes(
            seedInArray
                ? Array.from(seedIndexes).filter((index) => index !== originalIndex)
                : Array.from(seedIndexes).concat(originalIndex)
        );
    };

    const renderSubregionSeedsList = (): JSX.Element[] => {
        const subregionRender: JSX.Element[] = [];

        seedsByRegion.forEach((regionalSeeds, region) => {
            if (selectedRegion === "all" || selectedRegion === region) {
                subregionRender.push(
                    <div
                        key={region}
                        id={region}
                        ref={(ref) => regionRefMap.current.set(region, ref)}
                        data-region={region}
                    >
                        <Typography variant="h5" gutterBottom color="primary">
                            <b>{region}</b>
                        </Typography>
                        {subregionFragments(regionalSeeds)}
                    </div>
                );
            }
        });

        return subregionRender;
    };

    const subregionFragments = (regionalSeeds: Seed[]) => {
        const subregionSeedGroups = seedsPerSubregion(regionalSeeds);

        const subregionRender: JSX.Element[] = [];

        subregionSeedGroups.forEach((seeds, subregion) => {
                subregionRender.push(
                    <React.Fragment key={subregion}>
                        <Typography variant="body1" color="primary">{subregion}</Typography>
                        {seeds.map((seed) => (
                            <div key={seed.originalIndex} style={{display: "inline"}}>
                                <IconButton
                                    onClick={() => handleKorokSeedClick(seed.originalIndex)}
                                >
                                    <img
                                        height={25}
                                        width={25}
                                        src={seed.found ? KorokSeedFoundIcon : KorokSeedIcon}
                                        alt={seed.found ? "Korok Seed Found" : "Korok Seed"}
                                    />
                                </IconButton>
                            </div>
                        ))}
                    </React.Fragment>
                );
        });

        return subregionRender
    };


    const getPreviousRegion = (
        currentRegion: TowerRegion
    ): TowerRegion | null => {
        const regionKeys = Array.from(seedsByRegion.keys());
        const currentIndex = regionKeys.indexOf(currentRegion);
        if (currentIndex > 0) {
            return regionKeys[currentIndex - 1];
        }
        return null;
    };

    useEffect(() => {
        const seeds: Seed[] = koroksData.map((seed, index) => ({
            originalIndex: index,
            loc: seed.loc,
            region: seed.region as TowerRegion || TowerRegion.UNKNOWN,
            found: false,
        }));

        setKoroksState(createDataActionState(seeds))
    }, [userSeedIndexes]);

    useEffect(() => {
        if (koroksViewState.data != undefined) {
            const regionsData = groupByRegion(koroksViewState.data);
            setSeedsByRegion(regionsData);
        }
    }, [koroksViewState])


    const render = renderSubregionSeedsList();

    return (
        <Container className={classes.root} maxWidth={false}>
            <Grid container spacing={1} justify="flex-start" wrap="wrap">
                <Grid item xs={12} lg={6} className={classes.korokGrid}>
                    <Paper elevation={3} className={classes.korokCountContainer}>
                        <Typography variant="subtitle1" color="primary">
                            <b>Koroks Remaining: {userKoroksRemaining}/900</b>
                        </Typography>
                        <BotwKoroksAutocomplete />
                    </Paper>
                    <Paper className={classes.paperKoroksContainer} elevation={4}>
                        {render}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Koroks;