import React, {useContext, useEffect, useMemo, useRef} from 'react';
import Loading from './Loading';
import {createStyles, Grid, Theme} from "@material-ui/core";
import FoodMaterialTile from './FoodMaterialTile';
import {makeStyles} from "@material-ui/core/styles";
import {FoodContext} from "../context/FoodContext";
import {FoodMaterial} from "../models/FoodMaterial";
import {Effect} from "../models/Effect";
import {MeContext} from "../context/MeContext";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
        root: {
            maxHeight: "100%",
            flexGrow: 1
        }
    })
));

export function useScrollTabsToTop() {
    const tabPanel: HTMLDivElement | null = document.querySelector("[role='tabpanel']");
    const tabsRef = useRef<HTMLDivElement | null>(tabPanel);
    console.log(tabsRef)
    function scrollToTop() {
        let scrollHeight = 0
        if (tabPanel) {
            scrollHeight = tabPanel.scrollTop;
        }
        const scrollDuration = 500;

        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;

        let scrollCount = 0;
        let scrollMargin;
        function step() {

            setTimeout(() => {
                if (tabPanel?.scrollTop !== 0) {
                    scrollCount += 1;
                    scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                    tabPanel?.scrollTo(0, (scrollHeight - scrollMargin));
                    requestAnimationFrame(step);
                }
            }, 2);
        }
        requestAnimationFrame(step);
    }

    function scrollTabsToTop() {
        const tabsContainer = document.querySelector("[role='tabpanel']");
        console.log(tabsContainer);
        if (tabsContainer) {
            scrollToTop();
        }
    }

    return { tabsRef, scrollTabsToTop };
}

export function useDebouncedEffect(callback: () => void, delay: number, dependencies: any[]) {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback();
        }, delay);
        return () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, dependencies);
}


const FoodMaterialsList = () => {
    const classes  = useStyles();
    const { foodMaterialsViewState, foodPrepState, getFoodMaterialsByEffect, filterArmorFood } = useContext(FoodContext);
    const { tabsRef, scrollTabsToTop } = useScrollTabsToTop();
    const { userArmorMaterialsState } = useContext(MeContext);

    useDebouncedEffect(() => {
        scrollTabsToTop();
    }, 500, [foodPrepState.data?.foodEffect]);

    useMemo(() => {
        getFoodMaterialsByEffect(foodPrepState.data?.foodEffect!, true)
    }, [foodPrepState])

    if (foodMaterialsViewState.data === null) {
        return <Loading message={"armor"}/>;
    }

    return (
        <Grid container className={classes.root}>
            {foodMaterialsViewState.data && foodMaterialsViewState.data!!
                .map( (foodMaterial: FoodMaterial, index: number) => {
                return (<Grid ref={tabsRef} item key={index} xs={12}><FoodMaterialTile foodMaterial={foodMaterial} index={index} /></Grid>)
            })}
        </Grid>
    );
};

export { FoodMaterialsList as default };