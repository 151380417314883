import React, {useContext} from 'react';
import {MeContext} from "../context/MeContext";
import {User} from "../models/User";
import {UserArmorDisplay} from "../models/UserArmorDisplay";
import Loading from "./Loading";
import LevelIndicator from "./LevelIndicator";
import BotwListItem from './BotwListItem';
import {createStyles, darken, Grid, IconButton, Theme, Typography, useMediaQuery} from "@material-ui/core";
import InactiveIcon from "@material-ui/icons/GpsFixedTwoTone";
import ActiveIcon from "@material-ui/icons/AdjustTwoTone";
import {makeStyles} from "@material-ui/core/styles";
import {Armor} from "../models/Armor";
import CloseIcon from "@material-ui/icons/Close";
import {ArmorContext} from "../context/ArmorContext";
import {Effect} from "../models/Effect";
import botw_theme from "../styles/theme";
import {DeleteForever} from "@material-ui/icons";
// import {ArmorContext} from "../context/ArmorContext";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
        imgContainer: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        content: {
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            paddingLeft: theme.spacing(2)
        },
        remove: {
            display: "flex",
            flexFlow: "center center",
        },
        typographyActive: {
            [theme.breakpoints.down('xs')]: {
                fontSize: "1.25rem"
            }
        },
        typographyInactive: {
            color: darken(theme.palette.common.white, .35)
        },
        unfocused: {
            backgroundColor: `rgba(${theme.palette.common.black}, .5)`
        }
    })
));

interface Props {
    armorDisplay?: UserArmorDisplay | null,
    armor?: Armor | null
}

const UserArmorDisplayTile = ((props: Props) => {
    const smUp = useMediaQuery(botw_theme.breakpoints.up("sm"));
    const { armor, armorDisplay } = props;
    const classes = useStyles();
    const { meState, updateUserArmorLevel, updateUserArmorLevelState, updateUserArmorActiveState, userArmorInventoryState, tokenState } = useContext(MeContext);
    const { addArmorToInventory, removeArmorFromInventory } = useContext(ArmorContext);
    if (meState.loading) return <Loading message={"me"} />

    if (updateUserArmorLevelState.loading) {
        // console.log(updateUserArmorLevelState)
    }

    const me: User = meState.data!!;
    const token: string = tokenState.data!!;

    const updateArmorLevel = (armorLevel: number) => {
        updateUserArmorLevel(meState.data!!.id, armorDisplay!!, armorLevel);
    }

    const armorInMyInventory = (armor: Armor) => {
        const myArmorInventory = userArmorInventoryState.data!! || [];
        if (myArmorInventory != null) {
            const match: UserArmorDisplay[] = myArmorInventory.filter( (ma) => {
                return armor.id === ma.armor.id;
            });

            return match.length > 0;
        }
        return false;
    }
    return(
        <BotwListItem alignItems="center" bgEffect={
           armorDisplay && armorDisplay.active ? Effect.ELECTRIC : armorDisplay && !armorDisplay.active ? Effect.ELIXER : Effect.NONE
        }>

            {armorDisplay != null && (
                <>
                    <IconButton
                        onClick={() => {
                           removeArmorFromInventory(token, me.id, armorDisplay?.armor)
                        }}
                        style={ {
                            color: botw_theme.palette.warning.dark,
                            position: "absolute",
                            top: -10,
                            right: -10,
                        }}><DeleteForever />
                    </IconButton>
                    {/*{smUp && (*/}
                    <Grid item>
                        <IconButton onClick={() => updateUserArmorActiveState(me.id, armorDisplay, !armorDisplay?.active)}>
                            {armorDisplay.active ? <ActiveIcon color="primary" /> : <InactiveIcon />}
                        </IconButton>
                    </Grid>
                    {/*)}*/}
                    <Grid item>
                        <IconButton onClick={() => updateUserArmorActiveState(me.id, armorDisplay, !armorDisplay?.active)}>
                            <img alt={armorDisplay.armor.displayName} src={"/images/armor/" + armorDisplay.armor.imgUrl} height="50" width="50" />
                        </IconButton>
                    </Grid>
                    <Grid item className={classes.content} sm>
                        <Typography variant="h6" noWrap>{armorDisplay.armor.displayName}</Typography>
                        <LevelIndicator type="stars" maxLevel={armorDisplay.armor.maxArmorLevel} level={armorDisplay.armorLevel} updateArmorLevel={updateArmorLevel} />
                    </Grid>
                </>
            )}
            {armor != null && (
                <>
                    <Grid item>
                        <IconButton onClick={() => addArmorToInventory(token, me.id, armor)}>
                            <img alt={armor.displayName} src={"/images/armor/" + armor.imgUrl} height="50" width="50" />
                        </IconButton>
                    </Grid>
                    <Grid item className={classes.content}>
                        <Typography className={classes.typographyInactive} variant="h6" noWrap>{armor.displayName}</Typography>
                        <LevelIndicator type="stars" maxLevel={armor.maxArmorLevel} level={0} />
                    </Grid>
                </>
            )}
        </BotwListItem>
    )
});

export { UserArmorDisplayTile as default }