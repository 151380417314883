import React, {useContext} from "react";
import {KorokContext} from "../context/KorokContext";
import {MeContext} from "../context/MeContext";
import {createStyles, fade, IconButton, Input, InputAdornment, Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Loading from "./Loading";
import {BackspaceTwoTone} from "@material-ui/icons";

const styles = (theme: Theme) => createStyles({
    searchContainer: {
        position: "relative",
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: theme.spacing(2)
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.45),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.75),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create(['width', 'height']),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30ch',
            height: "2rem",
            '&:focus': {
                width: '34ch',
            },
        },
    },
    desktopAutocomplete: {
        display: "none",
        position: "absolute",
        height: 0,
        [theme.breakpoints.up('sm')]: {
            height: "unset"
        }
    },
    searchAndResults: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    }
});

interface Props extends WithStyles<typeof styles> {};

const BotwKoroksAutocomplete = withStyles(styles)( ({classes}: Props) => {
    const { setSearchKoroksQuery, searchKoroksQueryState } = useContext(KorokContext);
    const { meState } = useContext(MeContext);



    if (meState.loading && meState.data == null) return <Loading message={"me"} />;
    if (!setSearchKoroksQuery) return <Loading message={"search"} />;

    return (
        <div className={classes.searchAndResults}>
            <div className={classes.searchContainer}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    {/*to-do: onChange update the searchQuery, set dispatch to useEffect*/}
                    <Input
                        type="text"
                        placeholder="Korok Subregion Search"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={searchKoroksQueryState?.text}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                            setSearchKoroksQuery({text: event.target.value});
                        }}
                        onFocus={ (e) => {
                            if (e.target.value.length > 0) e.target.select();
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setSearchKoroksQuery({text: ""})}
                                >

                                    {searchKoroksQueryState?.text?.length > 0 ? <BackspaceTwoTone /> : <></>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
            </div>
        </div>
    );
});

export default BotwKoroksAutocomplete;