import React, {useContext, useState} from 'react';
import {MeContext} from "./MeContext";
import {
    ActionState,
    createActionState,
    createDataActionState,
    createErrorActionState,
    createLoadingActionState
} from "./ActionState";
import {Shrine} from "../models/Shrine";
import {CONFIG} from "../config";
import {UserShrine} from "../models/UserShrine";
import {ShrineStatus} from "../models/ShrineStatus";

interface ShrineContextProps {
    shrinesViewState: ActionState<Shrine[]>,
    setShrinesViewState: (shrineState: ActionState<Shrine[]>) => void;
    shrinesState: ActionState<Shrine[]>
    setShrinesState: (shrinesState: ActionState<Shrine[]>) => void;
    getShrines: () => Promise<ActionState<Shrine[]>>;
    setSearchShrinesQuery: (newSearchQuery: SearchShrinesQuery) => void;
    searchShrinesQueryState: SearchShrinesQuery;
    addShrineToUser: (userId: number, shrine: Shrine) => Promise<ActionState<UserShrine>>;
    addShrineState: ActionState<UserShrine>;
    removeShrineFromUser: (userId: number, shrine: Shrine) => Promise<ActionState<UserShrine>>;
    removeShrineState: ActionState<UserShrine>;
    shrineSortByState : string;
    setShrineSortByState : (fieldName: string) => void;
}

interface SearchShrinesQuery {
    text: string;
}

interface Props {
    children: React.ReactNode
}

export const ShrineContext = React.createContext<ShrineContextProps>({} as ShrineContextProps);

export const ShrineContextProvider = (props: Props) => {
    const [shrinesState, setShrinesState] = useState<ActionState<Shrine[]>>(createActionState);
    const [shrineSortByState, setShrineSortByState] = useState<string>("region");
    const [shrinesViewState, setShrinesViewState] = useState<ActionState<Shrine[]>>(createActionState);
    const [searchShrinesQueryState, setSearchShrinesQueryState] = useState<SearchShrinesQuery>({} as SearchShrinesQuery);
    const [addShrineState, setAddShrineState] = useState<ActionState<UserShrine>>(createActionState());
    const [removeShrineState, setRemoveShrineState] = useState<ActionState<UserShrine>>(createActionState());
    const { meState, userShrinesState, setUserShrinesState, setUserShrinesViewState, tokenState } = useContext(MeContext);

    const getShrines = async () => {
        try {
            setShrinesViewState(createLoadingActionState(shrinesViewState));

            const token: string = tokenState.data!!
            const response = await fetch(CONFIG.apiRoot + '/shrines',{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const shrines: Shrine[] = await response.json();
            setShrinesState(createDataActionState(shrines));
            // setShrinesViewState(createDataActionState(shrines));
            return Promise.resolve(shrinesViewState);
        } catch (error) {
            console.error(error);
            setShrinesState(createErrorActionState(error, shrinesState));
            setShrinesViewState(createErrorActionState(error, shrinesState))
            return Promise.resolve(shrinesViewState);
        }
    };

    const setSearchShrinesQuery = (newSearchQuery: SearchShrinesQuery) => {
        if (!newSearchQuery.text && searchShrinesQueryState.text) {
            setShrinesViewState(createDataActionState(shrinesState.data!!));
            setUserShrinesViewState(createDataActionState(userShrinesState.data!!));
            setSearchShrinesQueryState(newSearchQuery);
            return;
        }

        setSearchShrinesQueryState(newSearchQuery);

        if (newSearchQuery.text) {
            setUserShrinesViewState(
                createDataActionState(
                    userShrinesState.data!!.filter((userShrine: UserShrine) => {
                        return userShrine.shrine.displayName!!.toLowerCase().indexOf(newSearchQuery.text.toLowerCase()) >= 0
                            || userShrine.shrine.region!!.toLowerCase().replace("_", " ").indexOf(newSearchQuery.text.toLowerCase()) > -1
                    })
                )
            )
            setShrinesViewState(
                createDataActionState(
                    shrinesState.data!!.filter((shrine: Shrine) => {
                        return (
                            shrine.displayName!!.toLowerCase().indexOf(newSearchQuery.text.toLowerCase()) > -1
                            || shrine.region!!.toLowerCase().replace("_", " ").indexOf(newSearchQuery.text.toLowerCase()) > -1
                        );
                    })
                )
            )
        }
    }

    const addShrineToUser = async (userId: number, shrine: Shrine) => {
        // set local version for visual loading before fetching and updating
        try {
            const userShrine: UserShrine = {
                user: meState.data!!,
                shrine: shrine,
                status: ShrineStatus.EXPLORED,
                plundered: false
            }
            setUserShrinesState(createDataActionState((userShrinesState.data!! || []).concat(userShrine)));

            //api
            setAddShrineState(createLoadingActionState(addShrineState));
            const token = tokenState.data!!
            const response = await fetch(
                CONFIG.apiRoot + '/users/' + userId + '/shrines/' + shrine.id,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const newUserShrine: UserShrine = await response.json();
            setAddShrineState(createDataActionState(newUserShrine));
            return Promise.resolve(addShrineState);

        } catch (error) {
            console.error(error);
            setAddShrineState(createErrorActionState(error, addShrineState));
            return Promise.resolve(addShrineState);
        }
    };

    const removeShrineFromUser = async (userId: number, shrine: Shrine) => {
        //local
        setUserShrinesState(createDataActionState((userShrinesState.data || []).filter(userShrine => userShrine.shrine !== shrine)));

        //api
        try {
            setRemoveShrineState(createLoadingActionState(removeShrineState));
            const token = tokenState.data!!
            const response = await fetch(
                CONFIG.apiRoot + '/users/' + userId + '/shrines/' + shrine.id,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const deleteUserShrine: UserShrine = await response.json();
            setRemoveShrineState(createDataActionState(deleteUserShrine));
            return Promise.resolve(removeShrineState);

        } catch (error) {
            console.error(error);
            setRemoveShrineState( createErrorActionState(error, removeShrineState));
            return Promise.resolve(removeShrineState);
        }
    };

    return (
        <ShrineContext.Provider
            value={{
                getShrines, shrinesViewState, setShrinesViewState,
                shrinesState, setShrinesState,
                setSearchShrinesQuery, searchShrinesQueryState,
                addShrineToUser, addShrineState,
                removeShrineFromUser, removeShrineState,
                shrineSortByState, setShrineSortByState
            }}
        >
            {props.children}
        </ShrineContext.Provider>
    )
}