import React from 'react';
import {MeContextProvider} from "./MeContext";
import {CommonContextProvider} from "./CommonContext";
import {ContextDataLoader} from "../components/ContextDataLoader";
import {ArmorContextProvider} from "./ArmorContext";
import {ShrineContextProvider} from "./ShrineContext";
import {FoodContextProvider} from "./FoodContext";
import {KorokContextProvider} from "./KorokContext";

type Props = {
    children: JSX.Element;
};

export const ApiContextProvider = (props: Props) => {
    return (
        <CommonContextProvider>
            <MeContextProvider>
                <ArmorContextProvider>
                    <ShrineContextProvider>
                        <KorokContextProvider>
                            <FoodContextProvider>
                                <ContextDataLoader>
                                    {props.children}
                                </ContextDataLoader>
                            </FoodContextProvider>
                        </KorokContextProvider>
                    </ShrineContextProvider>
                </ArmorContextProvider>
            </MeContextProvider>
        </CommonContextProvider>
    );
};
