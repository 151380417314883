import React, {useEffect, useState} from 'react';
import {useAuth0} from '../react-auth0-spa';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionState,
  createActionState,
  createDataActionState,
  createErrorActionState,
  createLoadingActionState
} from "./ActionState";
import {User} from "../models/User";
import {UserArmorDisplay} from "../models/UserArmorDisplay";
import {UserArmorsMaterial} from "../models/UserArmorsMaterial";
import {UserShrine} from "../models/UserShrine";
import {CONFIG} from "../config";
import {PatchUserArmor} from "../models/PatchUserArmor";
import {ShrineStatus} from "../models/ShrineStatus";
import {PatchUserShrine} from "../models/PatchUserShrine";
import {UserFood} from "../models/UserFood";
import {PatchUserFood} from "../models/PatchUserFood";
import {Auth0GetTokenError} from "../models/Auth0Api";
import jwt_decode from "jwt-decode";
import {ArmorContext} from "./ArmorContext";
import {UserKorok} from "../models/UserKorok";

interface MeContextProps {
  getToken: () => void;
  tokenState: ActionState<string>;

  getMe: () => void;
  meState: ActionState<User>;

  getUserArmorInventory: (userId: number) => Promise<ActionState<UserArmorDisplay[]>>;
  setUserArmorInventoryState: (userArmorDisplay: ActionState<UserArmorDisplay[]>) => void;
  userArmorInventoryState: ActionState<UserArmorDisplay[]>;

  setUserArmorViewState: (userArmorDisplay: ActionState<UserArmorDisplay[]>) => void;
  userArmorViewState: ActionState<UserArmorDisplay[]>;

  getUserArmorMaterials: (userId: number, armorIds: number[]) => Promise<ActionState<UserArmorsMaterial[]>>;
  userArmorMaterialsState: ActionState<UserArmorsMaterial[]>;

  updateUserArmorLevel: (userId: number, armorDisplay: UserArmorDisplay, newArmorLevel: number) => Promise<ActionState<UserArmorDisplay>>;
  updateUserArmorLevelState: ActionState<UserArmorDisplay>;
  updateUserArmorActiveState: (userId: number, armorDisplay: UserArmorDisplay, active: boolean) => Promise<ActionState<UserArmorDisplay>>;
  userArmorActiveState: ActionState<UserArmorDisplay>;

  getUserShrines: (userId: number) => Promise<ActionState<UserShrine[]>>;
  userShrinesState: ActionState<UserShrine[]>;
  setUserShrinesState: (userShrinesState: ActionState<UserShrine[]>) => void;
  userShrinesViewState: ActionState<UserShrine[]>;
  setUserShrinesViewState: (userShrinesViewState: ActionState<UserShrine[]>) => void;

  updateUserShrineStatusState: (userId: number, updatedUserShrine: UserShrine, status: ShrineStatus) => Promise<ActionState<UserShrine>>;
  updateUserShrinePlunderedState: (userId: number, updatedUserShrine: UserShrine, plundered: boolean) => Promise<ActionState<UserShrine>>;
  updateUserShrineState: ActionState<UserShrine>;

  getUserKoroks: (userId: number) => Promise<ActionState<UserKorok[]>>;
  userKoroksState: ActionState<UserKorok[]>;
  setUserKoroksState: (userKoroksState: ActionState<UserKorok[]>) => void;
  userKoroksViewState: ActionState<UserKorok[]>;
  setUserKoroksViewState: (userKoroksViewState: ActionState<UserKorok[]>) => void;

  getUserFoodInventory: (userId: number) => Promise<ActionState<UserFood[]>>;
  userFoodInventoryState: ActionState<UserFood[]>;
  setUserFoodInventoryState: (userFoodInventoryState: ActionState<UserFood[]>) => void;
  userFoodInventoryViewState: ActionState<UserFood[]>;
  setUserFoodInventoryViewState: (userFoodInventoryViewState: ActionState<UserFood[]>) => void;
  updateUserFoodActiveState: (userId: number, userFood: UserFood, active: boolean) => Promise<ActionState<UserFood>>;
  userFoodActiveState: ActionState<UserFood>;
}

export const MeContext = React.createContext<MeContextProps>({} as MeContextProps);

interface Props {
  children: React.ReactNode;
}

export const MeContextProvider = (props: Props) => {
  const [meState, setMeState] = useState<ActionState<User>>(createActionState());

  const [tokenState, setTokenState] = useState<ActionState<string>>(createActionState());

  const [userArmorViewState, setUserArmorViewState] = useState<ActionState<UserArmorDisplay[]>>(createActionState());
  const [userArmorInventoryState, setUserArmorInventoryState] = useState<ActionState<UserArmorDisplay[]>>(createActionState());

  const [updateUserArmorLevelState, setUpdateUserArmorLevelState] = useState<ActionState<UserArmorDisplay>>(createActionState());
  const [userArmorActiveState, setUserArmorActiveState] = useState<ActionState<UserArmorDisplay>>(createActionState());

  const [userArmorMaterialsState, setUserArmorMaterialsState] = useState<ActionState<UserArmorsMaterial[]>>(createActionState());

  const [userShrinesState, setUserShrinesState] = useState<ActionState<UserShrine[]>>(createActionState());
  const [userShrinesViewState, setUserShrinesViewState] = useState<ActionState<UserShrine[]>>(createActionState());

  const [updateUserShrineState, setUpdateUserShrineState] = useState<ActionState<UserShrine>>(createActionState());

  const [userKoroksState, setUserKoroksState] = useState<ActionState<UserKorok[]>>(createActionState());
  const [userKoroksViewState, setUserKoroksViewState] = useState<ActionState<UserKorok[]>>(createActionState());

  const [userFoodInventoryState, setUserFoodInventoryState] = useState<ActionState<UserFood[]>>(createActionState());
  const [userFoodInventoryViewState, setUserFoodInventoryViewState] = useState<ActionState<UserFood[]>>(createActionState());

  const [userFoodActiveState, setUserFoodActiveState] = useState<ActionState<UserFood>>(createActionState());
  // const [userFoodMaterialsState, setUserFoodMaterialsState] = useState<ActionState<UserFoodsMaterial[]>>(createActionState());

  const getToken = async() => {
    try {
      const token = window.localStorage.getItem("anonJwt");
      if (token && token !== "") {
        const decoded = jwt_decode(token);
        // console.log(decoded);
        setTokenState(createDataActionState(token));
      } else {
        await getAnonJwt();
      }
    } catch (error) {
      console.log("no valid token found", error)
      window.localStorage.setItem("anonJwt", "");
      await getAnonJwt();
    }
  }

  const getAnonJwt = async() => {
    try {
      const anonTokenResponse = await fetch(CONFIG.apiRoot + `/users/auth/${uuidv4()}/token`);

      const token = await anonTokenResponse.text();

      window.localStorage.setItem("anonJwt", token);
      setTokenState(createDataActionState(token));
    } catch (error) {
      console.log(error);
      setTokenState(createErrorActionState(JSON.stringify(error), tokenState));
    }
  }

  // const getAuth0Token = async() => {
  //   try {
  //     const auth0Token: Promise<string> = await getTokenSilently();
  //   } catch (error) {
  //     const auth0Error: Auth0GetTokenError = error;
  //   }
  // }

  const getMe = async () => {
    setMeState(createLoadingActionState(meState));
    if (tokenState.data) {
      try {
        const response = await fetch(CONFIG.apiRoot + '/users/me', {
          headers: {
            Authorization: `Bearer ${tokenState.data}`,
          },
        });
        const user: User = await response.json();
        setMeState(createDataActionState(user));
        return Promise.resolve(meState);
      } catch (error) {
        console.error(error);
        setMeState(createErrorActionState(error, meState));
      }
    }
  };

  const getUserArmorInventory = async (userId: number) => {
    //api
    try {
      setUserArmorViewState(createLoadingActionState(userArmorViewState));
      const token = tokenState.data!!
      const response = await fetch(CONFIG.apiRoot + '/users/' + userId + '/armor', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const userArmorInventory: UserArmorDisplay[] = await response.json();
      setUserArmorInventoryState(createDataActionState(userArmorInventory));
      setUserArmorViewState(createDataActionState(userArmorInventory));
      return Promise.resolve(userArmorViewState);

    } catch (error) {
      console.error(error);
      setUserArmorInventoryState(createErrorActionState(error, userArmorInventoryState));
      setUserArmorViewState(createErrorActionState(error, userArmorInventoryState));
      return Promise.resolve(userArmorViewState);
    }
  };
  const getUserArmorMaterials = async (userId: number, armorIds: number[]) => {
    try {
      //local
      setUserArmorMaterialsState(createLoadingActionState(userArmorMaterialsState));

      //api
      const token: string = tokenState.data!!
      const armorIdsString: string = armorIds.join(",");
      const response = await fetch(
          CONFIG.apiRoot + '/users/' + userId + '/armors/materials?ids=' + armorIdsString,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );

      const materials: UserArmorsMaterial[] = await response.json();
      setUserArmorMaterialsState(createDataActionState(materials));
      return Promise.resolve(userArmorMaterialsState);

    } catch (error) {
      console.error(error);
      return Promise.resolve(userArmorMaterialsState)
    }
  }

  const updateUserArmorLevel = async(userId: number, armorDisplay: UserArmorDisplay, newArmorLevel: number) => {
    if (!userArmorInventoryState.data?.find( uad => uad === armorDisplay )) {
    }
    try {
      //set things locally
      setUserArmorInventoryState(createDataActionState((userArmorInventoryState.data || []).map((userArmorDisplay: UserArmorDisplay) => {
        if (userArmorDisplay.armor.id === armorDisplay.armor.id) {
          userArmorDisplay.armorLevel = newArmorLevel;
        }
        return userArmorDisplay;
      })));

      // then update the api
      const userArmorPatch: PatchUserArmor = {
        armorLevel: newArmorLevel,
        active: armorDisplay.active
      }

      const token = tokenState.data!!

      const response = await fetch(
           CONFIG.apiRoot + "/users/" + userId + "/armor/" + armorDisplay.armor.id,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': "application/json"
            },
            body: JSON.stringify(userArmorPatch)
          });

      const userArmorDisplay: UserArmorDisplay = await response.json();
      setUpdateUserArmorLevelState(createDataActionState(userArmorDisplay));
      // TODO handle error!
      return Promise.resolve(updateUserArmorLevelState);

    } catch (error) {
      console.error(error);
      setUpdateUserArmorLevelState(createErrorActionState(error, updateUserArmorLevelState));
      return Promise.resolve(updateUserArmorLevelState);

    }
  }
  const updateUserArmorActiveState = async(userId: number, armorDisplay: UserArmorDisplay, active: boolean) => {
    try {
      //set things locally
      setUserArmorInventoryState(createDataActionState((userArmorInventoryState.data || []).map((userArmorDisplay: UserArmorDisplay) => {
        if (userArmorDisplay.armor.id === armorDisplay.armor.id) {
          userArmorDisplay.active = active;
        }
        return userArmorDisplay;
      })));

      // then update the api
      const userArmorPatch: PatchUserArmor = {
        armorLevel: armorDisplay.armorLevel || 0,
        active: active
      }

      const token = tokenState.data!!

      const response = await fetch(
          CONFIG.apiRoot + "/users/" + userId + "/armor/" + armorDisplay.armor.id,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': "application/json"
            },
            body: JSON.stringify(userArmorPatch)
          });

      const userArmorDisplay: UserArmorDisplay = await response.json();
      // TODO handle error!

      return Promise.resolve(updateUserArmorLevelState);

    } catch (error) {
      console.error(error);
      setUserArmorActiveState(createErrorActionState(error, userArmorActiveState));
      return Promise.resolve(updateUserArmorLevelState);

    }
  }

  //User Shrines
  const getUserShrines = async (userId: number) => {
    if (userShrinesState.data != null) {
      setUserShrinesViewState(createDataActionState(userShrinesState.data!!));
      return Promise.resolve(userShrinesState);
    } else {
      //api
      try {
        setUserShrinesViewState(createLoadingActionState(userShrinesState));
        const response = await fetch(CONFIG.apiRoot + '/users/' + userId + '/shrines', {
          headers: {
            Authorization: `Bearer ${tokenState.data}`,
          },
        });
        const userShrines: UserShrine[] = await response.json();
        setUserShrinesState(createDataActionState(userShrines));
        return Promise.resolve(userShrinesViewState);

      } catch (error) {
        console.error(error);
        setUserShrinesState(createErrorActionState(error, userShrinesState));
        return Promise.resolve(userShrinesViewState);
      }
    }
  };

  const updateUserShrinePlunderedState = async(userId: number, updatedUserShrine: UserShrine, plundered: boolean) => {
    try {
      //set things locally
      setUserShrinesState(createDataActionState((userShrinesState.data || []).map((userShrine: UserShrine) => {
        if (userShrine.shrine.id === updatedUserShrine.shrine.id) {
          userShrine.plundered = plundered;
        }
        return userShrine;
      })));

      // then update the api
      setUpdateUserShrineState(createLoadingActionState(updateUserShrineState));
      const userShrinePatch: PatchUserShrine = {
        status: updatedUserShrine.status,
        plundered: plundered
      }

      const token = tokenState.data!!
      const response = await fetch(
          CONFIG.apiRoot + "/users/" + userId + "/shrines/" + updatedUserShrine.shrine.id,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': "application/json"
            },
            body: JSON.stringify(userShrinePatch)
          });

      const shrineResponse: UserShrine = await response.json();
      setUpdateUserShrineState(createDataActionState(shrineResponse))
      return Promise.resolve(updateUserShrineState);

    } catch (error) {
      console.error(error);
      setUpdateUserShrineState(createErrorActionState(error, updateUserShrineState));
      return Promise.resolve(updateUserShrineState);

    }
  }
  const updateUserShrineStatusState = async(userId: number, updatedUserShrine: UserShrine, status: ShrineStatus) => {
    try {
      //set things locally
      setUserShrinesState(createDataActionState((userShrinesState.data || []).map((userShrine: UserShrine) => {
        if (userShrine.shrine.id === updatedUserShrine.shrine.id) {
          userShrine.status = status;
          if (status == ShrineStatus.NONE) {userShrine.plundered = false};
        }
        return userShrine;
      })));

      // then update the api
      setUpdateUserShrineState(createLoadingActionState(updateUserShrineState));

      const userShrinePatch: PatchUserShrine = {
        status: status,
        plundered: status == ShrineStatus.NONE ? false : updatedUserShrine.plundered
      }


      const token = tokenState.data!!
      const response = await fetch(
          CONFIG.apiRoot + "/users/" + userId + "/shrines/" + updatedUserShrine.shrine.id,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': "application/json"
            },
            body: JSON.stringify(userShrinePatch)
          });

      const shrineResponse: UserShrine = await response.json();
      setUpdateUserShrineState(createDataActionState(shrineResponse))
      return Promise.resolve(updateUserShrineState);

    } catch (error) {
      console.error(error);
      setUpdateUserShrineState(createErrorActionState(error, updateUserShrineState));
      return Promise.resolve(updateUserShrineState);
    }
  }


  //User Koroks
  const getUserKoroks = async (userId: number) => {
    if (userKoroksState.data != null) {
      setUserKoroksViewState(createDataActionState(userKoroksState.data!!));
      return Promise.resolve(userKoroksState);
    } else {
      //api
      try {
        setUserKoroksViewState(createLoadingActionState(userKoroksState));

        const userKoroks: UserKorok[] = localStorage.getItem("userKoroks") ? JSON.parse(localStorage.getItem("userKoroks")!!) : [];

        setUserKoroksState(createDataActionState(userKoroks));
        return Promise.resolve(userKoroksViewState);

      } catch (error) {
        console.error(error);
        setUserKoroksState(createErrorActionState(error, userKoroksState));
        return Promise.resolve(userKoroksViewState);
      }
    }
  };


  const getUserFoodInventory = async (userId: number) => {
    if (userFoodInventoryState.data != null) {
      setUserFoodInventoryViewState(createDataActionState(userFoodInventoryState.data!!));
      return Promise.resolve(userFoodInventoryState);
    } else {
      //api
      try {
        setUserFoodInventoryViewState(createLoadingActionState(userFoodInventoryState));
        const token = tokenState.data!!
        const response = await fetch(CONFIG.apiRoot + '/users/' + userId + '/food', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userFood: UserFood[] = await response.json();
        setUserFoodInventoryState(createDataActionState(userFood));
        return Promise.resolve(userFoodInventoryViewState);
      } catch (error) {
        console.error(error);
        setUserFoodInventoryState(createErrorActionState(error, userFoodInventoryState));
        return Promise.resolve(userFoodInventoryViewState);
      }
    }
  };
  // const userFoodMaterials = async (userId: number, foodIds: number[]) => {
  //   try {
  //     //local
  //     setUserFoodMaterialsState(createLoadingActionState(userFoodMaterialsState));
  //
  //     //api
  //     const token: string = tokenState.data!!
  //     const foodIdsString: string = foodIds.join(",");
  //     const response = await fetch(
  //         CONFIG.apiRoot + '/users/' + userId + '/foods/materials?ids=' + foodIdsString,
  //         {
  //           method: 'GET',
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //     );
  //
  //     const materials: UserArmorsMaterial[] = await response.json();
  //     setUserArmorMaterialsState(createDataActionState(materials));
  //     return Promise.resolve(userArmorMaterialsState);
  //
  //   } catch (error) {
  //     console.error(error);
  //     return Promise.resolve(userArmorMaterialsState)
  //   }
  // };
  // };
  const updateUserFoodActiveState = async(userId: number, userFood: UserFood, active: boolean): Promise<ActionState<UserFood>> => {
    try {
      //set things locally
      setUserFoodInventoryState(createDataActionState((userFoodInventoryState.data || []).map((uf: UserFood) => {
        if (uf.foodId === userFood.foodId) {
          userFood.active = active;
        }
        return userFood;
      })));

      // then update the api
      const userFoodPatch: PatchUserFood = {
        active: active,
        displayName: userFood.displayName
      }

      const token = tokenState.data!!

      const response = await fetch(
          CONFIG.apiRoot + "/users/" + userId + "/food/" + userFood.foodId,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': "application/json"
            },
            body: JSON.stringify(userFoodPatch)
          });

      const responseUserFood: UserFood = await response.json();
      setUserFoodActiveState(createDataActionState(responseUserFood));
      // TODO handle error!

      return Promise.resolve(userFoodActiveState);

    } catch (error) {
      console.error(error);
      setUserFoodActiveState(createErrorActionState(error, userFoodActiveState));
      return Promise.resolve(userFoodActiveState);

    }
  }

  return (
      <MeContext.Provider
          value={{
            getToken, tokenState,
            getMe, meState,
            getUserArmorInventory, userArmorInventoryState, setUserArmorInventoryState,
            userArmorViewState, setUserArmorViewState,
            getUserArmorMaterials, userArmorMaterialsState,
            updateUserArmorLevel, updateUserArmorLevelState,
            updateUserArmorActiveState, userArmorActiveState,
            getUserShrines, userShrinesState, userShrinesViewState, setUserShrinesViewState,
            setUserShrinesState,
            getUserKoroks, userKoroksState, userKoroksViewState, setUserKoroksViewState,
            setUserKoroksState,
            updateUserShrineStatusState, updateUserShrinePlunderedState, updateUserShrineState,
            getUserFoodInventory, userFoodInventoryState, setUserFoodInventoryState, userFoodInventoryViewState, setUserFoodInventoryViewState,
            updateUserFoodActiveState, userFoodActiveState
          }}
      >
        {props.children}
      </MeContext.Provider>
  );
};
