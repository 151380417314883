import {
    createStyles,
    Grid,
    GridDirection,
    GridItemsAlignment,
    GridJustification,
    Paper,
    Theme
} from "@material-ui/core";
import React from "react";
import {fade, makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {Effect} from "../models/Effect";
import {elixerEffects, foodEffects} from "../lib/CookingCalcs";

const useStyles = makeStyles( (theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            borderColor: theme.palette.grey["500"],
            borderStyle: "solid",
            borderWidth: "2px",
            backgroundColor: fade(theme.palette.grey["700"], .85),
            color: theme.palette.grey["100"],
            width: "100%",
            height: "100%"
        },
        paperBacking: {
            padding: "2px",
            backgroundColor: fade(theme.palette.grey["600"], .85),
            margin: "4px",
            width: "100%"
        },
        bgElixer : {
            backgroundColor: fade(theme.palette.info.dark, .85)
        },
        bgTime: {
            backgroundColor: fade(theme.palette.common.white, .85)
        },
        bgFoodEffect: {
            backgroundColor: fade(theme.palette.primary.main, .85)
        },
        bgFaded: {
            backgroundColor: `${fade(theme.palette.common.black, .3)}`
        }
    })
);

interface Props {
    children: React.ReactNode,
    direction?: GridDirection,
    justify?: GridJustification,
    alignItems?: GridItemsAlignment,
    bgEffect?: Effect
}

const BotwListItem = (props: Props) => {
    const classes = useStyles();
    return (
        <Paper className={classNames(
            [classes.paperBacking],
            [
                props.bgEffect !== undefined && (
                    ((props.bgEffect === Effect.ELIXER || elixerEffects.includes(props.bgEffect)) && classes.bgElixer) ||
                    (props.bgEffect === Effect.TIME && classes.bgTime) ||
                    (foodEffects.includes(props.bgEffect) && classes.bgFoodEffect) ||
                    (props.bgEffect === Effect.NONE && classes.bgFaded)
                )
            ]
        )}>
            <Paper className={classNames([classes.paper], [props.bgEffect === Effect.NONE && classes.bgFaded])}>
                <Grid container direction={props.direction} spacing={0} justify={props.justify} alignItems={props.alignItems} style={{ position: 'relative'}}>
                    {props.children}
                </Grid>
            </Paper>
        </Paper>
    )
}

export { BotwListItem as default }