export interface ActionState<T> {
    data: T | null;
    loading: boolean;
    error: any | null;
}

export const createActionState = <T>(): ActionState<T> => {
    return {data: null, loading: true, error: null};
}

export const createDataActionState = <T>(data: T): ActionState<T> => {
    return {data: data, loading: false, error: null};
}

export const createLoadingActionState = <T>(previousState: ActionState<T>): ActionState<T> => {
    return {data: previousState.data, loading: true, error: null};
}

export const createErrorActionState = <T>(error: any, previousState: ActionState<T>): ActionState<T> => {
    return {data: previousState.data, loading: false, error: error};
}

