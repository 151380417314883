import React, {useContext} from 'react';
import {MeContext} from "../context/MeContext";
import Loading from "./Loading";
import Error from "./Error";
import BotwListItem from './BotwListItem';
import {createStyles, Grid, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Shrine} from "../models/Shrine";
import {UserShrine} from "../models/UserShrine";
import {ShrineStatus} from "../models/ShrineStatus";
import unexploredDlcShrineImg from "../assets/icon-dlc-unexplored.svg";
import exploredDlcShrineImg from "../assets/icon-dlc-explored.svg";
import beatenDlcShrineImg from "../assets/icon-dlc-beaten.svg";
import {Region} from "../models/Region";
import {ShrineContext} from "../context/ShrineContext";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
        imgContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        content: {
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            paddingLeft: theme.spacing(2)
        },
        remove: {
            display: "flex",
            flexFlow: "center center",
        },
        contentTitle: {
            margin: 0,
            padding: 0
        },
        contentRegion: {
            margin: 0,
            padding: 0,
            marginTop: theme.spacing(-1)
        },
        inactive: {
            opacity: 0.4
        }

    })
));

interface Props {
    shrine: Shrine,
    userShrine?: UserShrine
}

const   ShrineDisplayTile = ((props: Props) => {
    const { shrine, userShrine } = props;
    const classes = useStyles();

    const {updateUserShrineStatusState, meState, userShrinesState, updateUserShrinePlunderedState } = useContext(MeContext);
    const { addShrineToUser } = useContext(ShrineContext);

    if (meState.loading && meState.data === null) return <Loading message={"Finding myself..."} />;
    if (meState.error) return <Error message={"meState: " + meState.error} />;

    if (userShrinesState.loading && userShrinesState.data === null) return <Loading message={"Finding myself..."} />;
    if (userShrinesState.error) return <Error message={"userShrinesState: " + userShrinesState.error} />;


    if (!shrine) {
        return <Loading message={"shrine"} />;
    }

    return (
        <BotwListItem alignItems="center">
            <Grid item>
                {!userShrine && (
                    <IconButton onClick={() => addShrineToUser(meState.data!!.id, shrine)} >
                        {shrine.region !== Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to explored`} src={"/images/icon-shrine-unexplored.svg"} height="40" width="40" />}
                        {shrine.region === Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to explored`} src={unexploredDlcShrineImg} height="25" width="25" />}
                    </IconButton>
                )}
                {userShrine && userShrine.status === ShrineStatus.BEATEN &&(
                    <IconButton onClick={() => updateUserShrineStatusState(meState.data!!.id, userShrine!!, ShrineStatus.NONE)}>
                        {shrine.region !== Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to explored`} src={"/images/icon-shrine-beaten.svg"} height="40" width="40" />}
                        {shrine.region === Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to explored`} src={beatenDlcShrineImg} height="25" width="25" />}
                    </IconButton>
                )}
                {userShrine && userShrine.status === ShrineStatus.EXPLORED &&(
                    <IconButton onClick={() => updateUserShrineStatusState(meState.data!!.id, userShrine!!, ShrineStatus.BEATEN)}>
                        {shrine.region !== Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to unexplored`} src={"/images/icon-shrine-explored.svg"} height="40" width="40" />}
                        {shrine.region === Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to unexplored`} src={exploredDlcShrineImg} height="25" width="25" />}
                    </IconButton>
                )}
                {userShrine && userShrine.status === ShrineStatus.NONE &&(
                    <IconButton onClick={() => updateUserShrineStatusState(meState.data!!.id, userShrine!!, ShrineStatus.EXPLORED)}>
                        {shrine.region !== Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to explored`} src={"/images/icon-shrine-unexplored.svg"} height="40" width="40" />}
                        {shrine.region === Region.CHAMPIONS_BALLAD && <img alt={`click to set ${shrine.displayName} to explored`} src={unexploredDlcShrineImg} height="25" width="25" />}
                    </IconButton>
                )}
            </Grid>
            <Grid item>
                {userShrine && userShrine.plundered && (
                        <IconButton onClick={() => updateUserShrinePlunderedState(meState.data!!.id, userShrine!!, !userShrine?.plundered)}>
                            <img alt={`click if you didn't find the treasure chests in ${shrine.displayName}`} src={"images/icon-chest-active.png"} height="25" width="25"/>
                        </IconButton>
                )}
                {userShrine && !userShrine.plundered && (
                    <IconButton disabled={userShrine.status === ShrineStatus.NONE} onClick={() => updateUserShrinePlunderedState(meState.data!!.id, userShrine!!, !userShrine?.plundered)}>
                        <img alt={`click if you found the treasure chests in ${shrine.displayName}`} className={userShrine.status === ShrineStatus.NONE ? classes.inactive : ""} src={"images/icon-chest-inactive.png"} height="25" width="25"/>
                    </IconButton>
                )}
                {!userShrine && (
                    <IconButton disabled={true}>
                        <img alt="disabled because you haven't explored this shrine yet" className={classes.inactive} src={"images/icon-chest-inactive.png"} height="25" width="25"/>
                    </IconButton>
                )}
            </Grid>
            <Grid item className={classes.content}>
                <Typography variant="h6" noWrap className={classes.contentTitle}>{shrine.displayName}</Typography>
                <Typography variant="overline" className={classes.contentRegion}>{shrine.region}</Typography>
            </Grid>
        </BotwListItem>
    )


});

export { ShrineDisplayTile as default }