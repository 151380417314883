import React from "react";
import {createStyles, Paper, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles( {
    paper: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.text.secondary,
        padding: theme.spacing(4)
    }
})

interface Props extends WithStyles<typeof styles> {
    message: string
}

const Loading = withStyles(styles)(({classes, message}: Props) => {
    return(
        <Paper elevation={5} className={classes.paper}>
            <Typography variant="h6">{message.toUpperCase()}</Typography>
        </Paper>
    )
});

export default Loading;
