import React, { useContext } from "react";
import { ShrineContext } from "../context/ShrineContext";
import Error from "./Error";
import Loading from "./Loading";
import { createStyles, Grid, Theme  } from "@material-ui/core";
import { Shrine } from "../models/Shrine";
import ShrineDisplayTile from "./ShrineDisplayTile";
import { makeStyles } from "@material-ui/core/styles";
import { MeContext } from "../context/MeContext";
import { UserShrine } from "../models/UserShrine";
import { sortBy } from "ramda";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: "100%",
    },
  })
);

const AllShrinesList = () => {
  const classes = useStyles();
  const {
    shrinesViewState,
    setSearchShrinesQuery,
    searchShrinesQueryState,
    shrineSortByState,
  } = useContext(ShrineContext);
  const {
    meState,
    userShrinesViewState,
    userShrinesState,
    tokenState,
  } = useContext(MeContext);

  // useEffect( () => {
  //     setSearchShrinesQuery(searchShrinesQuery);
  // }, [userShrinesState.data])

  if (shrinesViewState.loading) {
    return <Loading message={"shrines"} />;
  }

  if (shrinesViewState.data === null) {
    return <Loading message={JSON.stringify(shrinesViewState)} />;
  }

  if (shrinesViewState.error) {
    return <Error message={shrinesViewState.error} />;
  }

  if (meState.loading) {
    return <Loading message={"user"} />;
  }

  if (tokenState.loading) {
    return <Loading message={"token"} />;
  }

  if (tokenState.error) {
    return <Error message={"token: " + JSON.stringify(tokenState.error)} />;
  }

  if (shrinesViewState.error) {
    return (
      <Error message={"shrines: " + JSON.stringify(shrinesViewState.error)} />
    );
  }

  if (meState.error) {
    return <Error message={"me: " + JSON.stringify(meState.error)} />;
  }

  let userShrineMap = new Map<number, UserShrine>();

  if (userShrinesViewState.data != null) {
    userShrinesViewState.data!!.forEach((userShrine: UserShrine) => {
      userShrineMap.set(userShrine.shrine.id, userShrine);
    });
  }

  const sortByDisplayName = (shrine : Shrine) => shrine.displayName;
  const sortByRegion = (shrine : Shrine) => shrine.region;
  let data = sortBy(sortByDisplayName, shrinesViewState.data || []); // default sort
  if(shrineSortByState === "region") {
    data = sortBy(sortByRegion, shrinesViewState.data || []);
  }

  return (
    <Grid container className={classes.root}>
      {data.map((shrine: Shrine, index: number) => (
        <ShrineDisplayTile
          key={index}
          shrine={shrine}
          userShrine={userShrineMap.get(shrine.id)}
        />
      ))}
    </Grid>
  );
};

export { AllShrinesList as default };
