import React, {useContext, useState} from 'react';
import {MeContext} from "./MeContext";
import {
    ActionState,
    createActionState,
    createDataActionState,
    createErrorActionState,
    createLoadingActionState
} from "./ActionState";
import {Korok} from "../models/Korok";
import {CONFIG} from "../config";
import {UserKorok} from "../models/UserKorok";
import koroksData from '../assets/koroks/koroks.json';
import Koroks, {Seed} from "../views/Koroks";
import koroks from "../views/Koroks";
import {UserShrine} from "../models/UserShrine";
import {Shrine} from "../models/Shrine";
import {create} from "domain";


interface KorokContextProps {
    koroksViewState: ActionState<Seed[]>,
    setKoroksViewState: (korokState: ActionState<Seed[]>) => void;
    koroksState: ActionState<Seed[]>
    setKoroksState: (koroksState: ActionState<Seed[]>) => void;
    getKoroks: () => Promise<ActionState<Seed[]>>;
    setSearchKoroksQuery: (newSearchQuery: SearchKoroksQuery) => void;
    searchKoroksQueryState: SearchKoroksQuery;
    addKorokToUser: (userId: number, korok: Korok) => Promise<ActionState<UserKorok>>;
    addKorokState: ActionState<UserKorok>;
    removeKorokFromUser: (userId: number, korok: Korok) => Promise<ActionState<UserKorok>>;
    removeKorokState: ActionState<UserKorok>;
    korokSortByState : string;
    setKorokSortByState : (fieldName: string) => void;
}

interface SearchKoroksQuery {
    text: string;
}

interface Props {
    children: React.ReactNode
}

export const KorokContext = React.createContext<KorokContextProps>({} as KorokContextProps);

export const KorokContextProvider = (props: Props) => {
    const [userKoroksState, setUserKoroksState] = useState<ActionState<UserKorok[]>>(createDataActionState([]));
    const [koroksState, setKoroksState] = useState<ActionState<Seed[]>>(createDataActionState([]));
    const [korokSortByState, setKorokSortByState] = useState<string>("subregion");
    const [koroksViewState, setKoroksViewState] = useState<ActionState<Seed[]>>(createActionState);
    const [searchKoroksQueryState, setSearchKoroksQueryState] = useState<SearchKoroksQuery>({} as SearchKoroksQuery);
    const [addKorokState, setAddKorokState] = useState<ActionState<UserKorok>>(createActionState());
    const [removeKorokState, setRemoveKorokState] = useState<ActionState<UserKorok>>(createActionState());
    const { meState, setUserKoroksViewState, tokenState } = useContext(MeContext);

    const getKoroks = async () => {
        try {
            setKoroksViewState(createLoadingActionState(koroksViewState));

            // const koroks: Korok[] = koroksData.map( (data: {id: string, loc: string, coords: number[][]}) => {
            //     const { id, loc } = data;
            //     return {
            //         id,
            //         subregion: loc,
            //     }
            // })
            const koroks: Seed[] = []
            setKoroksState(createDataActionState(koroks));
            setKoroksViewState(createDataActionState(koroks));
            return Promise.resolve(koroksViewState);
        } catch (error) {
            console.error(error);
            setKoroksState(createErrorActionState(error, koroksState));
            setKoroksViewState(createErrorActionState(error, koroksState))
            return Promise.resolve(koroksViewState);
        }
    };

    const setSearchKoroksQuery = (newSearchQuery: SearchKoroksQuery) => {
        if (!newSearchQuery.text && searchKoroksQueryState.text) {
            setKoroksViewState(createDataActionState(koroksState.data!!));
            setUserKoroksViewState(createDataActionState(userKoroksState.data!!));
            setSearchKoroksQueryState(newSearchQuery);
            return;
        }

        setSearchKoroksQueryState(newSearchQuery);

        if (newSearchQuery.text) {
            // setUserKoroksViewState(
            //     createDataActionState(
            //         userKoroksState.data!!.filter((userKorok: UserKorok) => {
            //             return userKorok.korok.subregion!!.toLowerCase().replace("_", " ").indexOf(newSearchQuery.text.toLowerCase()) > -1
            //         })
            //     )
            // )
            setKoroksViewState(
                createDataActionState(
                    koroksState.data!!.filter((korok: Seed) => {
                        return korok.loc!!.toLowerCase().replace("_", " ").indexOf(newSearchQuery.text.toLowerCase()) > -1;
                    })
                )
            )
        }
    }

    const addKorokToUser = async (userId: number, korok: Korok) => {
        // set local version for visual loading before fetching and updating
        try {
            const userKorok: UserKorok = {
                user: meState.data!!,
                korok: korok,
                plundered: true
            }
            // setUserKoroksState(createDataActionState((userKoroksState.data!! || []).concat(userKorok)));

            //api
            setAddKorokState(createLoadingActionState(addKorokState));
            // const token = tokenState.data!!
            //
            // const response = await fetch(
            //     CONFIG.apiRoot + '/users/' + userId + '/koroks/' + korok.id,
            //     {
            //         method: 'POST',
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //         },
            //     }
            // );

            const newUserKorok: UserKorok = userKorok
            setAddKorokState(createDataActionState(newUserKorok));
            return Promise.resolve(addKorokState);

        } catch (error) {
            console.error(error);
            setAddKorokState(createErrorActionState(error, addKorokState));
            return Promise.resolve(addKorokState);
        }
    };

    const removeKorokFromUser = async (userId: number, korok: Korok) => {
        //local
        // setUserKoroksState(createDataActionState((userKoroksState.data || []).filter(userKorok => userKorok.korok !== korok)));

        //api
        try {
            const userKorok: UserKorok = {
                user: meState.data!!,
                korok: korok,
                plundered: false
            }
            setRemoveKorokState(createLoadingActionState(removeKorokState));
            // const token = tokenState.data!!
            // const response = await fetch(
            //     CONFIG.apiRoot + '/users/' + userId + '/koroks/' + korok.id,
            //     {
            //         method: 'DELETE',
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //         },
            //     }
            // );

            // const deleteUserKorok: UserKorok = await response.json();
            setRemoveKorokState(createDataActionState(userKorok));
            return Promise.resolve(removeKorokState);

        } catch (error) {
            console.error(error);
            setRemoveKorokState( createErrorActionState(error, removeKorokState));
            return Promise.resolve(removeKorokState);
        }
    };

    return (
        <KorokContext.Provider
            value={{
                getKoroks, koroksViewState, setKoroksViewState,
                koroksState, setKoroksState,
                setSearchKoroksQuery, searchKoroksQueryState,
                addKorokToUser, addKorokState,
                removeKorokFromUser, removeKorokState,
                korokSortByState, setKorokSortByState
            }}
        >
            {props.children}
        </KorokContext.Provider>
    )
}