import React from "react";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Cooking from "./views/Cooking";
import {useAuth0} from "./react-auth0-spa";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Armor from './views/Armor';
import {MeContextProvider} from "./context/MeContext";
import Upgrades from "./views/Upgrades";
import {ArmorContextProvider} from "./context/ArmorContext";
import Loading from "./components/Loading";
import Shrines from "./views/Shrines";
import {ShrineContextProvider} from "./context/ShrineContext";
import {FoodContextProvider} from "./context/FoodContext";
import {ContextDataLoader} from "./components/ContextDataLoader";
import {ApiContextProvider} from "./context/ApiContext";
import {OutboundLink} from "react-ga";
import Profile from "./views/Profile";
import Login from "./views/Login";
import Koroks from "./views/Koroks";
import {Box, Button, Card, Modal, Typography} from "@material-ui/core";
import botw_theme from "./styles/theme";

initFontAwesome();

const App = () => {
    const {loading} = useAuth0();
    const [open, setOpen] = React.useState(true);

    if (loading) {
        return (<Loading message={"hold your horses"}/>);
    }

    return (
        <ApiContextProvider>
            <Router history={history}>
                <Box id="app" height={'100vh'} display='flex' flexDirection='column'>
                    <NavBar/>
                    <Box overflowY='scroll' display={'flex'} flexGrow={1}>
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/armor" component={Armor} />
                            <Redirect from="/ingredients" to="/materials" />
                            <Redirect path="/materials" to="/upgrades" />
                            <Route path="/upgrades" component={Upgrades} />
                            <Route path="/shrines" component={Shrines} />
                            <Route path="/cooking" component={Cooking} />
                            <Route path="/profile" component={Profile} />
                            <Route path="/koroks" component={Koroks} />
                            <Route path="/login" component={Login} />
                        </Switch>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "center", width: "100%", backGround: botw_theme.palette.grey.A400 }}>
                        <OutboundLink eventLabel={"WHO MADE THIS?!"} to={"https://linkedin.com/in/topher-sikorra-b21828b/"} >
                            Made in Seattle, USA
                        </OutboundLink>
                    </Box>
                </Box>
            </Router>
        </ApiContextProvider>
    );
};

export default App;


