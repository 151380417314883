import React, {useContext, useEffect, useMemo} from "react";
import Paper from "@material-ui/core/Paper";
import {
    Button,
    Container,
    createStyles,
    fade,
    Grid, Snackbar,
    Theme,
    Typography, useMediaQuery,
    withStyles,
    WithStyles
} from "@material-ui/core";
import AllArmorList from "../components/AllArmorList";
import UserArmorMaterialsList from "../components/UserArmorMaterialsList";
import BotwArmorAutocomplete from "../components/BotwArmorAutocomplete";
import bg from "../assets/neat.jpg";
import FairyIconPng from "../assets/nes_fairy.png"
import {ArmorContext} from "../context/ArmorContext";
import {MeContext} from "../context/MeContext";
import botw_theme from "../styles/theme";
import {useHistory} from "react-router";
import {KeyboardArrowRight} from "@material-ui/icons";


const styles = (theme: Theme) => createStyles({
    root: {
        background: `url(${bg})`,
        backgroundSize: "cover",
        justifyContent: "center",
        paddingLeft: 0,
        height: "100%",
        maxHeight: "100%",
        overflowY: 'scroll',
        // height: "calc(100vh - 115px)",
        // [theme.breakpoints.up('sm')]: {
        //     height: "calc(100vh - 110px)"
        // }
    },
    paperArmorContainer: {
        maxHeight: "70vh",
        overflowY: "scroll",
        padding: theme.spacing(2),
        background: fade(theme.palette.secondary.contrastText, .5),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2)
        }

    },
    paperMaterialsContainer: {
        maxHeight: "75vh",
        overflowY: "scroll",
        padding: theme.spacing(2),
        background: fade(theme.palette.secondary.contrastText, .5)
    },
    armorGrid: {
        paddingBottom: theme.spacing(2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.sm,
        }
    },
    materialsGrid: {
        // display: "none",
        [theme.breakpoints.up('sm')]: {
            display: "block"
        }
    },
    materialsHeader: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontFamily: "Hylia",
        background: theme.palette.secondary.dark,
        color: theme.palette.primary.light,
        [theme.breakpoints.up('sm')]: {
            color: theme.palette.primary.light
        }
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    }
});

// const BigFatTooltip = withStyles( (theme: Theme) => ({
//     tooltip: {
//         fontSize: "1rem",
//         backgroundColor: theme.palette.secondary.contrastText,
//         color: theme.palette.secondary.light,
//         padding: theme.spacing(2),
//         fontFamily: "Hylia"
//     }
// }))(Tooltip);

interface Props extends WithStyles<typeof styles> {
    // armor: UserArmorDisplay
}

const Armor = withStyles(styles)(({classes}: Props) => {
    const { getAllArmor } = useContext(ArmorContext);
    const { meState, getUserArmorInventory, tokenState, userArmorMaterialsState } = useContext(MeContext);
    const smUp = useMediaQuery(botw_theme.breakpoints.up("sm"));
    const showAlert = useMemo(() => userArmorMaterialsState.data && userArmorMaterialsState.data.length > 0 || false, [userArmorMaterialsState])
    const history = useHistory();

    return (

        <Container className={classes.root} maxWidth={false}>
            <Grid container spacing={3} justify="center">
                <Grid item  xs={12} sm={8} md={6} lg={4} xl={3} className={classes.armorGrid}>
                    <BotwArmorAutocomplete />
                    <Paper className={classes.paperArmorContainer} elevation={4}>
                        <AllArmorList />
                    </Paper>
                </Grid>
                {smUp && <Grid item xs={12} sm={4} md={6} lg={8} xl={9}  className={classes.materialsGrid}>
                    <Typography variant="h5" align="center" className={classes.materialsHeader}>{
                        userArmorMaterialsState.data?.length! > 0 && "Wooing them Fairies like..."
                    }</Typography>
                    <Paper className={classes.paperMaterialsContainer} elevation={4}>
                        <UserArmorMaterialsList />
                    </Paper>
                </Grid>
                ||
                    <Snackbar open={showAlert} style={{ bottom: botw_theme.spacing(5)}}>
                        <Button variant="contained"
                                startIcon={<img height={35} width="auto" src={FairyIconPng} />}
                                endIcon={<KeyboardArrowRight fontSize="large" />}
                                onClick={() => history.push("/upgrades")}
                        >
                            Fairy Upgrades
                        </Button>
                    </Snackbar>}
            </Grid>
        </Container>
    )
});

export default Armor;
