import {useContext, useEffect} from "react";
import {MeContext} from "../context/MeContext";
import {createDataActionState} from "../context/ActionState";
import {ShrineContext} from "../context/ShrineContext";
import {ArmorContext} from "../context/ArmorContext";
import {FoodContext} from "../context/FoodContext";
import {CONFIG} from "../config";
import ReactGA from "react-ga";
import {UserArmorDisplay} from "../models/UserArmorDisplay";
import {FoodMaterial} from "../models/FoodMaterial";
import {KorokContext} from "../context/KorokContext";

interface Props {
    children: JSX.Element;
}

export const ContextDataLoader = (props: Props) => {
    const {getMe, getToken, tokenState, meState, getUserArmorInventory, userKoroksState, setUserKoroksViewState, getUserKoroks, userShrinesState, setUserShrinesViewState, getUserShrines, getUserArmorMaterials, updateUserArmorLevelState, userArmorMaterialsState} = useContext(MeContext);
    const {getAllArmor, armorInventoryState, userArmorInventoryState, setUserArmorViewState, setArmorViewState, searchArmorQueryState, setSearchArmorQuery, addArmorState} = useContext(ArmorContext);
    const {getShrines, shrinesState, setShrinesViewState, searchShrinesQueryState, setSearchShrinesQuery} = useContext(ShrineContext);
    const {getKoroks, koroksState, setKoroksViewState, searchKoroksQueryState, setSearchKoroksQuery} = useContext(KorokContext)
    const {getFood, getAllFoodMaterials, foodMaterialsState, foodMaterialsViewState, foodPrepState, initFood, getFoodMaterialsByEffect, setFoodMaterialsViewState, filterArmorFood} = useContext(FoodContext);

    useEffect(() => {
        getToken();
        if (CONFIG.env === "production") {
            ReactGA.set({ screenSize: `${window.screen.width}x${window.screen.height}`})
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);

    useEffect( () => {
        if (tokenState.data) {
            getMe();
        }
    }, [tokenState]);

    useEffect( () => {
        if (meState.data !== null && tokenState.data !== null) {
            //get armor, food, materials, shrines
            const _allArmor = getAllArmor();
            const _allShrines = getShrines();
            const _allMaterials = getAllFoodMaterials();
            const _allFoodMaterials = getFood();

            const _allUserArmorInventory = getUserArmorInventory(meState.data.id);
            const _allUserShrines = getUserShrines(meState.data.id);
            const _allUserKoroks = getUserKoroks(meState.data.id);
            initFood();
            // const _allUserFoods = getUserFoods(meState.data.id);
        }
    },[meState]);

    //Armor + Upgrades
    useEffect( () => {
        if (userArmorInventoryState.data !== null && armorInventoryState.data !== null) {
            if (!searchArmorQueryState.text || searchArmorQueryState.text.length === 0) {
                setArmorViewState(createDataActionState(armorInventoryState.data));
                setUserArmorViewState(createDataActionState(userArmorInventoryState.data));
            } else {
                setSearchArmorQuery(searchArmorQueryState)
            }
        }
    }, [userArmorInventoryState]);

    //Shrines
    useEffect( () => {
        // console.log(shrinesState.data, userShrinesState.data, searchShrinesQuery.text);
        if (shrinesState.data !== null && userShrinesState.data !== null) {
            if (!searchShrinesQueryState.text || searchShrinesQueryState.text.length === 0) {
                setShrinesViewState(createDataActionState(shrinesState.data));
                setUserShrinesViewState(createDataActionState(userShrinesState.data));
            } else {
                setSearchShrinesQuery(searchShrinesQueryState);
            }
        }
    }, [shrinesState, userShrinesState]);

    //Shrines
    useEffect( () => {
        // console.log(shrinesState.data, userShrinesState.data, searchShrinesQuery.text);
        if (koroksState?.data !== null && userKoroksState?.data !== null && searchKoroksQueryState) {
            if (!searchKoroksQueryState.text || searchKoroksQueryState.text.length === 0) {
                setKoroksViewState(createDataActionState(koroksState.data));
                setUserKoroksViewState(createDataActionState(userKoroksState.data));
            } else {
                setSearchKoroksQuery(searchKoroksQueryState);
            }
        }
    }, [koroksState, userShrinesState, searchKoroksQueryState]);

    //Koroks
    // useEffect( () => {
    //     // console.log(shrinesState.data, userShrinesState.data, searchShrinesQuery.text);
    //     if (koroksState.data !== null && userKoroksState.data !== null) {
    //         if (!searchKoroksQueryState.text || searchKoroksQueryState.text.length === 0) {
    //             setKoroksViewState(createDataActionState(koroksState.data));
    //             setUserKoroksViewState(createDataActionState(userKoroksState.data));
    //         } else {
    //             setSearchKoroksQuery(searchKoroksQueryState);
    //         }
    //     }
    // }, [koroksState, userKoroksState]);

    // Cooking
    useEffect( () => {
        if (foodMaterialsState.data) {
            console.log("I am also being called")
            // setFoodMaterialsViewState(createDataActionState(foodMaterialsState.data!!));
        }
    }, [foodMaterialsViewState]);

    useEffect( () => {
        if (userArmorInventoryState.data !== null && !userArmorInventoryState.error) {
            const armorIds: number[] = userArmorInventoryState.data.filter( (userArmorDisplay: UserArmorDisplay) => {
                if (userArmorDisplay.active) {
                    return userArmorDisplay;
                }
                return false;
            }).map( (userArmorDisplay: UserArmorDisplay) => {
                return userArmorDisplay.armor.id
            });
            getUserArmorMaterials(meState.data!!.id, armorIds);
        }
    }, [userArmorInventoryState.data, addArmorState.data, updateUserArmorLevelState.data]);

    useEffect(() => {}
    , [])

    // useEffect(() => {
    //     if (foodMaterialsViewState.data && userArmorMaterialsState.data) {
    //         const noArmorMaterials = foodMaterialsViewState.data?.filter((foodMaterial: FoodMaterial) => {
    //             if (filterArmorFood) {
    //                 return !userArmorMaterialsState.data?.map((material) => material.material.displayName).includes(foodMaterial.material.displayName)
    //             }
    //             return foodMaterial
    //         })
    //         setFoodMaterialsViewState(createDataActionState(noArmorMaterials))
    //     }
    // }, [userArmorMaterialsState])

    // useEffect( () => {
    //     if (foodPrepState.data !== null && foodPrepState.data.foodEffect !== null && foodMaterialsState.data !== null) {
    //         const currentEffect = foodPrepState.data.foodEffect;
    //
    //         console.log(foodPrepState.data.foodEffect);
    //         getFoodMaterialsByEffect(
    //             foodPrepState.data.foodEffect,
    //             true
    //         );
    //     }
    // }, [foodPrepState])

    return props.children;
};